/* === SIDEBAR BASE === */
.sidebarBase {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fafafa;
    height: 100%;
    padding: 22px 15px 10px;
    transition: all .4s ease;
    position: relative;
}

.smallButton{
    width: 32px !important;
    justify-content: center !important;
    height: 30px;
    margin-bottom: 10px;
}

.sidebarOpen {}

.sidebarOpenContainer {
    min-width: 260px !important;
    max-width: 260px !important;
}

.sidebarClosedContainer {
    height: 100%;
    max-width: 78px !important;
    min-width: 78px !important;
    padding: 22px 8px 10px;
}

.sidebarClosedContainer .leftMenuItem,
.sidebarClosedContainer .leftMenuItemActive {
    display: flex !important;
    justify-content: center !important;
    width: 32px !important;
    margin: 5px auto;
}

.sidebarClosedContainer .leftMenuItemActive {
    background-color: #eeedee !important;
    border-radius: 16px !important;
    width: 45px;
}

.sidebarClosedContainer .leftMenuItem p,
.sidebarClosedContainer .sideBarHeading,
.sidebarClosedContainer .announcementBox,
.sidebarClosedContainer .menuItemText {
    display: none !important;
}

.sidebarSettingsMobileOpen {}
.sidebarSettingsMobileClosed {}

.sidebarLogo {
    display: flex;
    align-items: center;
    gap: 7px;
    width: 100%;
}

.showLogoIcon{
    justify-content: center;
}

.showLogoIcon img:last-child {
    display: none;
}

.toggleButton {
    display: flex !important;
    justify-content: flex-end;
    width: 100% !important;
    cursor: pointer;
    position: absolute;
    right: 20px;
    margin-top: 8px;
}

.toggleButtonClosed {
    position: relative;
    left: 0;
    margin-top: 30px;
    margin-bottom: 0;
    text-align: center;
    justify-content: center;
}

.sideBarMenus {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin-top: 23px;
}

.sideBarHeading {
    text-wrap: nowrap;
    color: #000;
    font-family: GT Walsheim Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5px;
    margin-bottom: 11px;
}

/* === MENU ITEMS === */
.leftMenuItem,
.skeletonItem {
    align-items: center !important;
    border-radius: 9px !important;
    display: flex !important;
    padding: 8px !important;
    text-decoration: none !important;
    width: 100% !important;
}

.leftMenuItem:hover {
    background-color: #eeedee !important;
    text-decoration: none !important;
}

.leftMenuItem svg {
    min-width: 16px;
}

.leftMenuItem p {
    text-wrap: nowrap;
    color: #333 !important;
    display: block;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding-top: 0px !important;
    margin: 0;
    margin-left: .5rem !important;
    position: relative;
    top: 1px;
}

.leftMenuItemActive {
    background-color: #eeedee !important;
}

.leftSubMenuItem {
    align-items: center;
    border-radius: 9px;
    color: #333;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 11px;
    height: 30px;
    max-width: 192px;
    padding: 7px 8px;
    text-decoration: none;
    width: 100%;
}

.activeNavLink,
.leftSubMenuItem:hover {
    background-color: #eeedee;
    color: #333;
}

.menuItemText {}

.sidebarSubMenuMain {
    align-items: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.sidebarSubMenu {
    background-color: initial;
    border-image: linear-gradient(176deg,#eeedee,#fafafa) 1;
    border-left: 1px solid;
    display: none;
    flex-direction: column;
    list-style: none;
    margin-left: 16px;
    max-width: 206px;
    padding: 10px;
}

.showSubMenu {
    display: block;
}

.dropdownArrow {
    transition: .3s ease;
}

.activeSubMenu {}

.activeSubMenu .dropdownArrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.closedSubMenu {}

.hiddenHr {
    display: none;
}

.sidebarClosedContainer hr {
    background: #222 !important;
    border-radius: 7px !important;
    display: block !important;
    height: 1px !important;
}

/* === PROFILE SECTION === */
.firstHalf {
    align-items: center;
    display: flex;
}

.avatarSkeleton,
.profileImage {
    width: 38px;
    height: 38px;
    border-radius: 100%;
    object-fit: cover;
}

/* === PROJECT DROPDOWN === */
.projectDropdownMain {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.projectDropdown {
    background: #EEEDEE;
    border-radius: 16px;
    cursor: pointer;
    height: 74px;
    overflow-wrap: anywhere;
    padding: 10px 9px;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.projectDropdownClosed{
    height:62px;
    justify-content: center;
    margin-bottom: 17px;
}

.projectDropdownClosed .profileImage{
    width: 31px;
    height: 31px;
}

.projectInfoType{
    margin-left: auto;
    display: flex;
    gap: 6px;
}

.projectType {
    margin-top: 2px;
    border-radius: 9px;
    color: white;
    font-size: 10px;
    height: 20px;
    display: inline-flex;
    padding: 3px 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
    width: fit-content;
}

.typeUltra{
    background: #ffb502;
    box-shadow: 0 8px 20px 0 rgba(255,182,44,.2);
}

.typePartner{
    background-color: #ae02ff;
    box-shadow: 0 8px 20px 0 rgba(174,2,255,.2);
}

.typePro{
    background: #18f;
    box-shadow: 0 8px 20px 0 rgba(17,136,255,.2);
}

.typeDraft{
    color: #FFF;
    background: #5F5F5F !important;
}

.projectActive {
    color: #4397F7;
    background: #F3F3F3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width:100%;
}

.projectInactive {
    display: flex;
    align-items: center;
}

.projectDropdownItem {
    display: flex;
    border-radius: 9px;
    padding: 5px 6px;
    align-items: center;
}

.projectImageContainer {
    display: flex;
    justify-content: flex-start;
}

.projectImageContainer img{
    width:20px;
    height: 20px;
    object-fit: cover;
    display: block;
}

.projectSlug {
    padding-inline-start: 0.5rem;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 106%;
    vertical-align: middle;
}

.projectSmallActionButton {
    user-select: none;
    display: none;
    margin-left: auto;
    margin-right: 6px;
}

.projectSmallActionButton img{
    width: 14px;
    padding: 0;
}

@media (min-width: 768px) {
    .projectSmallActionButton {
        display: flex;
    }
}

.projectDropdownMenu {
    list-style: none;
    width: 100%;
    top: 100%;
    gap: 15px;
    background: #fff;
    position: absolute;
    padding: 10px;
    border-radius: 15px;
    margin-top: -5px;
    display: none;
    box-shadow: 0px 4px 22px 0px #57586F21;
    z-index: 2;
}

.projectDropdownMenu li{
    cursor: pointer;
    border-radius: 8px;
}

.projectDropdownMenu li:hover{
    background: #f3f3f3;
}

.dropdownMenuVisible {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-top: 3px;
    padding-bottom: 10px;
}

.dropdownMenuVisible li{
    list-style: none;
}

.dropdownMenuVisible li a {
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    vertical-align: middle;
    text-decoration: none;
    color: #333333;
    height: 25px;
    display: flex;
    align-items: center;
}

.dropdownMenuVisible li a:hover{
    color: #1188ff;
}

.dropdownMenuHidden {
    display: none;
}

.dropdownItem {}

.dropdownItemText {
    margin-inline-end: 3rem;
}

.accountDivTitle {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    margin-top: -4px;
}

.accountDivTitleMain {
    font-size: 14px;
    color:#000;
    font-weight: 500;
}

.accountDivSubTitle {
    color: #8C8E9C;
    font-size: 14px;
    margin-top: -7px;
}

.accountDivSubTitle span{
    color: #8C8E9C;
    font-size: 12px;
    font-weight: 500;
}

.chevronContainer {
    flex-basis: 100%;
    text-align: right;
    margin-right: 4px;
}

.chevronArrow {
    margin-inline-start: 0.5rem;
    transition: .3s ease;
}

.chevronArrow.active {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.addNewProjectBtn {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 106%;
    vertical-align: middle;
    padding: 15px 0;
    border-top: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    gap: 10px;
    cursor: pointer;
}

.addNewProjectText {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 1px;
}

.addIcon {}

.sharedProjectsTitle {}

.disabledProject {
    cursor: not-allowed;
}

.userAccountMenu{
    padding-top: 15px;
}

.userAccountMenuTitle{
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    vertical-align: middle;
    color: #A7A7A7;
}

.userAccountMenu ul{
    padding-left: 0;
    padding-top: 6px;
    display: flex;
    flex-direction: column;
    width:100%;
}

.userAccountMenu ul li{
    list-style: none;
}

.userAccountMenu ul li:hover{
    background: none;
}

.userAccountMenu ul li a{
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    vertical-align: middle;
    text-decoration: none;
    color: #333333;
    height: 30px;
    display: flex;
    align-items: center;
}

.userAccountMenu ul li a:hover{
    color: #1188ff;
}

/* === ANNOUNCEMENT BOX === */
.announcementBox {
    border-radius: 15px;
    padding: 12px 16px 15px;
    background: #7F48FB;
    width: 100%;
    margin-bottom: 5px;
    color: #fff;
}

.announcementText {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.announcementHead {}

.dotsIcon {
    padding: 10px 5px;
}

@media (max-width: 1328px)
{
    .announcementBox {
        display: none !important;
        cursor: pointer;
        min-width: calc(100% + 12px);
        text-align: center;
        padding: 15px;
    }

    .announcementBox button,
    .announcementBox .announcementText,
    .announcementBox .announcementHead span {
        display: none !important;
    }

    .announcementBox .announcementHead img {
        width: 22px;
        display: block;
        margin: 0 auto;
    }
}

@media screen and (max-height: 860px) {
    .announcementBox {
        display: none;
    }
}
