/* Styles for the main section next to the sidebar */
.community-section {
    flex-basis: 100%;
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
    padding: 22px 35px 0 35px;
    position: relative;
}

.community-section .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Container for table and footer */
.community-section .section-main {
    width: 100%;
    display: flex;
    min-height: calc(100vh - 150px);
    flex-direction: column;
    justify-content: space-between;
}

/* Custom Scrollbar */
.community-section::-webkit-scrollbar {
    width: 0.5em;
}

.community-section::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 5px;
}


/* ======================================== */
/* Table Styles                           */
/* ======================================== */

.table thead {
    border-bottom: 1px solid #E8E9EA;
}

.table thead th {
    font-weight: 500;
    color: #5B5B5B;
    vertical-align: middle;
}

/* Ensure Bootstrap's default thead display is respected */
.d-md-table-header-group {
    display: table-header-group !important;
}

@media (max-width: 767.98px) {
    .d-md-table-header-group {
        display: none !important; /* Hide on small screens */
    }
}


.table tbody {
    border-top: none !important;
}

.table tbody tr {
    border-bottom: 1px solid #E8E9EA !important;
}

.table tbody tr td {
    vertical-align: middle;
    border: none !important;
    font-weight: 500;
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
}

/* Avatar Icon */
.subscriber-name-icon {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background-color: #03ACFF;
    color: white;
    border-radius: 50%;
    flex-shrink: 0;
}

/* Action Dropdown Trigger */
.table-action-dropdown {
    cursor: pointer;
    padding: 5px;
    display: inline-flex;
    border-radius: 4px;
}

.table-action-dropdown:hover {
    background-color: #f8f9fa;
}

.table-action-dropdown::after {
    display: none !important;
}

/* Status Badges (Keep if using Status column) */
.table tbody tr td .badge {
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

/* Specific badge colors (adjust if needed) */
.badge-subscribed {
    color: #fff !important;
    background-color: #0d6efd !important;
}

.badge-unsubscribed {
    color: #fff !important;
    background-color: #6c757d !important;
}

/* ======================================== */
/* Pagination (PaginationFooter)           */
/* ======================================== */

.section-footer {
    width: 100%;
    padding: 15px 0;
    margin-top: 20px;
}

.section-footer .table-pagination {
    display: flex;
    align-items: center;
    justify-content: end;
}

.section-footer .table-pagination button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    height: 35px;
    text-align: center;
    background-color: #EEEDEE;
    color: #6B6B6B;
    border: none;
    border-radius: 10px !important;
    margin-right: 5px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.section-footer .table-pagination button:last-child {
    margin-right: 0;
}

.section-footer .table-pagination button:hover {
    background-color: #212322 !important;
    color: white !important;
}

.section-footer .table-pagination button.active {
    background-color: #212322 !important;
    color: white !important;
    cursor: default;
}

.section-footer .table-pagination button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #EEEDEE !important;
    color: #6B6B6B !important;
}


/* ======================================== */
/* Modals (Import, Confirmation, Add/Edit)*/
/* ======================================== */

/* General Modal Header Styling */
.create-biolink-header {
    padding: 15px 25px;
    border-bottom: 1px solid #E8E9EA;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create-biolink-heading {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0;
}

.import-subscribers-header {
    background: url('../assets/img/import-subscribers-header.png') no-repeat center;
    background-size: cover;
    padding: 80px 0 !important;
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.import-modal-body {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    margin-top: -20px;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    position: relative;
    z-index: 1;
}

.import-modal .modal-content .dropzone-wrapper4 {
    min-height: 120px !important;
    border: 1px dashed #A0A0A0 !important;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
}

.import-modal .modal-content .dropzone-wrapper4 .dropzone-desc {
    color: #6c757d;
}

.import-csv-file-btn {
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 50px;
    border: none;
}

/* Styles for the form inside the import modal mapping step */
#import_subscribers_form .form-group label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0.5rem;
}

#import_subscribers_form .form-group .form-select {
    background-color: #F6F6F6;
    border: none;
    border-radius: 10px;
    padding: 0.75rem 1rem;
    font-weight: 500;
    color: #333;
}

#import_subscribers_form .modal-footer button {
    border-radius: 50px;
    padding: 8px 20px;
}

/* Add/Edit Segment Modal Footer Buttons */
.new-primary-btn {
    background-color: #0d6efd;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
}

.new-primary-btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.new-primary-btn .primary-badge img {
    height: 1em;
    vertical-align: middle;
}

.btn-grey {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 8px 15px;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
}

.btn-grey .badge {
    padding: 0.2em 0.4em;
    font-size: 0.8em;
}


/* ======================================== */
/* Miscellaneous & Utility                */
/* ======================================== */

.btn-semi-rounded {
    border-radius: 12px !important;
}

/* Style for simple Edit button */
.edit-tag {
    display: inline-flex;
    height: 30px;
    padding: 7px 12px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 9px;
    background: #EEEDEE;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
    border: none;
}

.edit-tag:hover {
    background-color: #e2e6ea;
}

/* Styles for Checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 1rem;
    user-select: none;
    vertical-align: middle;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-checkbox .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.custom-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}