/* Navbar custom menu */
:root {
  --app-height: 100%;
  --app-height-half: 50%;
}

.navbar .dropdown-item svg {
  color: var(--gray-700);
}

.navbar .dropdown-item:active svg {
  color: var(--white);
}

.navbar-main {
  min-height: 0 !important;
  background: var(--white);
  border-bottom: 1px solid var(--gray-300);
}

[data-theme-style="dark"] .navbar-main {
  border-bottom: 1px solid var(--gray-100);
}

.navbar-absolute {
  position: absolute;
  width: 100%;
  margin: 0;
  z-index: 1;
}

.navbar-main .navbar-nav>li {
  padding: 0.5rem !important;
}

.navbar-logo {
  max-height: 2rem;
  height: 1.5rem !important;
}

.navbar-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.navbar-custom-toggler {
  padding: 0.5rem 0.8rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border-radius: 0.25rem;

  color: var(--gray-700);
  /* border-color: var(--gray-300); */
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.85);
}

/* Forms */
.input-group-text {
  font-size: 0.9rem;
}



.page-aside-bg {
  width: 40%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: no-repeat right;
  background-size: auto 100%;
}

.page-aside-bg5 {
  width: 40%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: no-repeat right;
  background-size: auto 100%;
}

.google-btn {
  border: none !important;
  background-color: #383838 !important;
  display: flex;
  max-width: 390px;
  height: 35px;
  padding: 13px 97px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  text-wrap: nowrap;
}

.google-btn:hover {
  color: #ffffff !important;
}

.animate__animated.animate__fadeIn {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

body {
  background-color: white !important;
}

#navbar {
  margin-bottom: 0.5rem !important;
}

.page-heading {
  color: #0E123B;
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
}

.page-subtitle {
  color: #64687D;
}

label strong {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.input {
  display: flex;
  width: 100%;
  height: calc(3em + 0.75rem + 2px);
  padding: 0.375rem 1.1rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #858585;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 0.25rem;
  background-color: #F6F6F6 !important;
  border-radius: 50px;
  /* background: #F6F6F6 !important; */
  border: none !important;
  align-items: center;

}

input {
  border: none;
  background: none;
  outline: none;
  height: 100%;
  color: rgba(14, 18, 59, 0.5) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.1%;
  flex-basis: 70%;
}

input::placeholder {
  color: rgba(91, 91, 91, 0.50);
}

.input-icon {
  flex-basis: 5%;
}

.auth-main .btn-primary {
  font-size: 14px !important;
}

.text {
  color: rgba(14, 18, 59, 0.25);
}

.border {
  border: none !important;
  border-top: 1px solid rgba(16, 3, 37, 0.1) !important;
}

@media(max-width:767px) {

  /* #navbar{
    display:none;
} */
  .card-body {
    padding: 1.25rem 15px !important;
  }

  .auth-main {
    position: static !important;
  }
  .plan-card{
    height: auto !important;
  }
}

.page-aside-bg {
  background-image: url("../assets/img/page-aside-bg1.jpg");
}

.page-aside-bg2 {
  background-image: url("../assets/img/page-aside-bg2.jpg");
}

.page-aside-bg3 {
  background-image: url("../assets/img/page-aside-bg3.jpg");
}

.page-aside-bg5 {
  background-image: url("../assets/img/page-aside-bg5.png");
}

nav {
  z-index: 1;
}

.auth-main {
  width: 100%;
  height: 100vh;
  height: calc(var(--app-height) - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.auth-main::-webkit-scrollbar {
  width: 0.25em;
}

.auth-main::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0);
  border-radius: 5px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#input-prefix {
  display: flex;
  align-items: center;
  color: rgba(91, 91, 91, 0.25);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.1%; /* 14.854px */
}

.profile-cir {
  position: relative;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.upload--profile {
  background: #0000005e;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0px auto;
  /* display: none; */
  position: absolute;
  /* padding: 20px; */
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme-main-right {
  display: flex;
  flex-wrap: wrap;
}
/* 
.theme-main-right .theme-selection-div {
  flex-basis: 20%;
} */

.theme-selection-div {
  padding: 5px;
}

.theme-selection-indiv {
  /* background-color: #F1F1F1; */
  border-radius: 20px;
  /* height: 250px; */
  /* padding: 16px 0px; */
  padding: 5px;
  border: 2px solid transparent ;
}

.themes-selection div div span {
  border-radius: 10px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  /* margin-right: 10px; */
  /* height: 250px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  font-weight: 600;
  overflow: hidden;
  border-radius: 100%;
}

.themes-selection div span img {
  width: 100% !important;
}

.theme-selection-indiv.selected-theme {
  border: 2px solid #FF4566 !important;
  /* width: 36px; */
  /* height: 36px; */
  /* overflow: hidden; */
  border-radius: 100%;
}

.category-main {
  display: flex;
  flex-wrap: wrap;
}

.category-pill-main {
  flex-basis: 25%;
  padding: 10px;
}

.category-pill {
  background-color: #f6f6f6;
  text-align: center;
  border-radius: 50px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid transparent;
}

.category-pill.active {
  border: 1px solid black;
}

/* @media(max-width: 768px) {
  .plan-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
} */

@media(max-width: 992px) {
  .plan-cards-container {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    padding: 15px;
  }

  .plan-card {
    width: 320px !important;
    /* height: 100%; */
  }
}

@media(max-width: 1200px) {
  .auth-main {
    width: 100%;
  }

  .plan-cards-container {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    padding: 15px;
  }

  .plan-card {
    /* padding: 25px; */
    max-width: 400px !important;
    width: 100% !important;
  }

  .page-aside-bg {
    display: none;
  }

  .page-aside-bg5 {
    display: none;
  }
}

.password-tc-box {
  padding: 1em;
  width: 75%;
  background-color: #ffffff;
  box-shadow: 0px 8px 13px rgb(86 85 106 / 7%);
  left: 0;
  z-index: 999;
}

.reset-password-tc-box {
  padding: 1em;
  width: 60%;
  background-color: #ffffff;
  box-shadow: 0px 8px 13px rgb(86 85 106 / 7%);
  top: 50%;
  left: 0;
  z-index: 999;
}

.password-tc-box .ptc-subtitle,
.reset-password-tc-box .ptc-subtitle {
  color: #8780ab;
}

.password-tc-box .ptc-subtitle{
  display: flex;
  gap: 5px;
}

.password-tc-box .ptc-subtitle img{
  width: 12px;
  display: block;
}

.password-tc-box .ptc-subtitle .ptc-img .reset-password-tc-box .ptc-subtitle .ptc-img {
  height: 0.8em;
  margin-right: 5px;
}

.walkthrough-logout {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 5px 15px;
  text-align: center !important;
  z-index: 111;
}

.choose-plan-container {
  /* width: 100%; */
  height: 100%;
  padding: 30px;
}

.plan-text-container {
  padding: 18px 18px 8px 18px; 
}

.choose-plan-heading {
  font-weight: 700;
  font-size: 35px;
  line-height: 110%;
  letter-spacing: -0.005em;
  color: #0E123B;
}

.choose-plan-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 147.1%;
  color: #64687D;
}
.choose-plan-type {
  width: 450px;
  position: relative;
  z-index: 10;
}
.choose-plan-type-text{
  font-weight: 500;
  color: #473E43;
  font-size: 14px;

}
.plan-cards-container {
  display: flex;
  /* flex-wrap: wrap; */
  /* align-items: center; */
  gap: 20px;
  /* height: 90%; */
  padding: 15px;
  margin-top: 3rem;
}
/* .community-main .plan-text-container{
  max-width: 100px;
} */

.community-main .plan-card{
  min-width: 209px;
  /* height: 335px !important; */
}
.plan-card-walkthrough,.plan-card {
  width: 100%;
  /* height: 345px; */
  /* padding: 20px; */
  padding-top: 11px;
  padding-left: 13px;
  padding-bottom: 13px;
  padding-right: 13px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.37);
  background: linear-gradient(180deg, #1D2329 0%, #0F1314 100%);
  box-shadow: 0px 0px 50px rgba(90, 93, 121, 0.1);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
  /* height: calc(100vh - 300px); */
  /* max-height: 900px !important; */
}

.plan-card-period {
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: -0.005em;
  color: #3A3A3A;
}

.plan-card-price {
  font-weight: 700;
  font-size: 22px;
  line-height: 110%;
  letter-spacing: -0.005em;
  color: #FF4524;
}

.features-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: -webkit-fill-available; */
}

.features-list-container {
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  /* margin-bottom: 30px; */
}
/* .features-main-container{
  margin-top: 35px;
} */
.plan-feature-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
}

.feature-list-text {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 119.1%;
  color: #212121;
}

.plan-trial-button {
  display: inline-flex;
  height: 30px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  font-size: 12px;
  font-weight: 500;
  color: #FEFEFD;
  border: none;
}

.blue-button {
  background-color: #1188FF;
}
.orange-button {
  background-color: #FF4524;
}
.yellow-button {
  background-color: #FFB502;
}
.purple-button {
  background-color: #9000FB;
}
.text-purple{
  color: #9000FB;
}
.text-blue{
  color: #1188FF;
}
.text-yellow{
  color: #FFB502;
}
.activate-profile{
  border-radius: 20px;
  border: none;
  background-color: #f8f8f8 !important;
  font-weight: 400;
  padding: 12px;
  width: 100%;
  text-align: center;
  width:600px
}


/* unaiz css start */
.upgrade-card{
  max-width: 700px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 0 20px #00000019;
  background: #FAFAFA;
  height: fit-content !important;
  padding: 0 !important;
  position: relative;
}
.upgrade-card2{
  max-width: 700px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 0 20px #00000019;
  background: #FAFAFA;
  height: fit-content !important;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
}
.cardhead-u{
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.09px;
}
.price-u{
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.08px;
}
.list-u{
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 119.1%; /* 16.674px */
}
.features-list-container{
  gap: 11px;
}
.schedule-toggle-u {
  background-color: transparent !important;
  padding: 0 !important;
}
.adv-plan-card{
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.37);
  background: #EEEDEE;
  display: flex;
  align-items: center;
  padding: 13px 17px;
  gap: 12px;
  margin: 10px 0;
  transition: 0.2s ease;
}
.free-tag{
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: GT Walsheim Pro;
  font-size: 12px;
  font-weight: 400;
  border-radius: 9px;
}
.clr-gray{
  background-color: #5F5F5F !important;
}
.clr-blue{
  background-color: #18F !important;
}
.clr-purple{
  background-color: #7F48FB !important;
}
.free-head{
  width: 100%;
}
.nowrap{
  white-space: nowrap;
}
.head-free-u{
  color: #333;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.09px;
  margin-bottom: 0;
}
.para-free-u{
  color: #333;
  font-family: GT Walsheim Pro;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.07px;
}
.pricetag-u{
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: #FFF;
  color: #333;
  font-family: GT Walsheim Pro;
  font-size: 12px;
  font-weight: 500;
}

@media(max-width:575px){
  .auth-main {
    height: calc(var(--app-height) - 52px);
  }
}

/* Unaiz CSS End */
.grey-box-shadow{
  box-shadow: 0 0 15px #5f5f5f;
}
.blue-box-shadow{
  box-shadow: 0 0 15px rgba(17, 136, 255, 0.65);
}
.yellow-box-shadow{
  box-shadow: 0 0 15px #ffbb34ac;
}
.changed-opacity {
  opacity: 0.3;
}

.purple-box-shadow{
  box-shadow: 0px 0px 30px -2px #8134FF;
}
.lifetime-terms{
  color: rgba(91, 91, 91, 0.50);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 111%;
  padding-right: 19px;
}
.lifetime-plan-card{
  width: 100%;
  max-width: 209px;
  padding-top: 11px;
  padding-left: 13px;
  padding-bottom: 13px;
  border-radius: 16px;
  background: linear-gradient(180deg, #1D2329 0%, #0F1314 100%);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-height: 900px !important;
  gap: 13px;
}
.lifetime-adv-plan-card {
  background-color: #fff !important;
  padding: 10px !important;
  gap: 10px;
}
.lifetime-adv-plan-card .head-free-u{
  color: #333 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 111%
}
/* new steps css here */
.background-area{
  width: 100%;
  height: 100dvh;
  background-color: #F7F7F8;
  padding: 20px;
  position: relative;
  inset: 0;
}
.steps-card{
  border-radius: 20px;
  background: #FFF;
  padding: 30px !important;
}
.plan-card-walkthrough,.plan-card-free{
  position: relative;
  z-index: 1;
}
.card2 {
  width: 508px;
  padding: 20px;
  max-height: 80dvh;
  overflow-y: auto;
}
.zaap-logo-area{
  width: 100%;
  position: fixed;
  bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accontred-text:hover{
  color: #FF1451;
}
.red-btn-u{
  display: flex;
  height: 30px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  background: #FF1451;
  box-shadow: 0px 4px 15px 0px rgba(49, 48, 67, 0.10);
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  border: none;
  outline: none;
} 
.red-btn-u>span{
  display: flex;
  height: 13px;
  padding: 0px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #CF2551;
}

.red-btn-u:disabled {
  background-color: gray;
  color: lightgray;
  cursor: not-allowed;
  opacity: 0.6;
}


.newpageheading{
  color: #333;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.11px;
}
.newpage-subtitle{
  color: #8E8E8E;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.1%;
  margin-top: 12px;
}
.accontred-text{
  color: #FF1451;
  text-decoration: none;
}
.new-input-area.newinput-u{
  height: 30px !important;
}

form .form-group .new-input-area{
  justify-content: start;
}

.newinput-u{
  border-radius: 12px;
  background: #EEEDEE;
  width: 390px;
  height: 35px;
  display: flex;
  overflow: hidden;
  padding:0 15px;
  margin-top: 6px;
}
.margin-top-38{
  margin-top: 38px;
}
.mt-44-u{
  margin-top: 44px;
}
.counter{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #18F;
  box-shadow: 0px 4px 20px rgba(103, 101, 122, 0.19);
  color: #FEFEFE;
  font-family: GT Walsheim Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.input-social{
  display: flex;
  align-items: center;
  border-radius: 9px;
  background: #EEEDEE;
  padding: 3px 6px;
}
.blue-btn2-u{
  display: inline-flex;
  height: 36px;
  padding: 9.6px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 9.6px;
  background: #18F;
  box-shadow: 0px 4.8px 12.6px 0px rgba(17, 136, 255, 0.36);
  color: #FFF;
  text-align: center;
  font-size: 14.4px;
  font-weight: 500;
  border: none;
}
.blue-btn2-u:hover {
  opacity: 0.8;
}
.cancelLink{
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
.socialhead{
  color: #333;
  margin-top: 20px;
}
.gray-btn-u{
  height: 30px;
  padding: 0px 13px;
  border-radius: 9px;
  background: #EEEDEE;
  color: #333;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.18px;
  border: none;
  outline: none;
}
.profile-pic-box{
  display: flex;
  align-items: center;
  gap: 16px;
}
.profhead{
  color: #333;
  font-size: 14px;
  font-weight: 500;
}
.uploded-imge-area{
  width: 77px;
  height: 77px;
  border-radius: 100%;
  border: 2px dashed #6B6B6B;
  padding: 3px;
}
.uploded-imge-area>img{
  width: 100%;
}
.upload--profile-btn{
  display: flex;
  height: 30px;
  padding: 11px 15px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  background: #F3F3F3;
  color: #333;
  font-size: 12px;
  font-weight: 500;
}
.input2u{
  border-radius: 9px;
  background: #EEEDEE;
  padding: 4px 15px;
  margin-top: 9px;
}
.profilecard{
  width: 200px;
  height: 292px;
  border-radius: 20px;
  background: #113037;
  position: relative;
}
.profile-body{
  width: 200px;
  height: 226px;
  border-radius: 17px;
  background: #1D4953;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.user-img-area{
  width: 69px;
  height: 69px;
  border-radius: 100%;
  background: #18F;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 20px;
  font-weight: 500; 
  margin-top: -2rem;
}
.usertitle-area{
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
  margin-top: 9px;
}
.caption{
  color: #8E8E8E;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.15px;
}
.rowflex-u{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}
.new-plan-card{
  display: flex;
  gap: 18px;
  margin-top: 25px;
}
.newcontinue-btn{
  height: 30px;
  padding: 7px 13px;
  border-radius: 9px;
  background: #EEEDEE;
  color: #333;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.18px;
  border: none;
  outline: none;
  margin-top: 23px;
}
.newmain{
  background: #F7F7F8;
  height: 100dvh;
  position: relative;
  inset: 0;
}
@media (max-width:640px) {
  .rowflex-u{
    flex-direction: column;
  }
  .new-plan-card{
    flex-direction: column;
  }
  .features-list-container{
    flex-direction: column !important;
  }
}
@media (max-width:450px) {
  .steps-card{
      width: 100%;
      padding: 20px;
  }
  .newinput-u {
    width: 100%;
  }
  input{
    width: 100%;
  }
}


/* Bio Page Description css start here */
.background-area{
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F7F7F8;
  padding: 20px;
}
.steps-card{
  border-radius: 20px;
  background: #FFF;
  padding: 20px;
}
.steps-card-width
{
max-width:450px;
}
.counter{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #18F;
  box-shadow: 0px 4px 20px rgba(103, 101, 122, 0.19);
  color: #FEFEFE;
  font-family: GT Walsheim Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.card-head2{
  color: #333;
  font-family: GT Walsheim Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-top: 11px;
}
.card-para{
  color: #333;
  font-family: GT Walsheim Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 24px;
}
.description-area-box {
  width: 100%;
  height: 288px;
  border-radius: 9px;
  background: #EEEDEE;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 20px 10px;
  margin-top: 9px;
  max-width: 365px;
  overflow: auto;
}
.description-area-box>textarea{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}
.regen-blue-btn2-u{
  display: inline-flex;
  height: 30px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  background: #18F;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.18px;
  margin-top: 10px;
  border: none;
}
.bottom-btn-area{
  display: flex;
  gap: 6px;
  margin-top: 20px;
}
.gray-btn-u{
  display: inline-flex;
  height: 30px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  background: #EEEDEE;
  color: #333;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 114.1%; /* 13.692px */
  letter-spacing: -0.18px;
}
.zaap-logo-area{
  width: 100%;
  position: fixed;
  bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:510px) {
  .steps-card{
    min-width: calc(100% - 30px)!important;
    margin: 15px;
  }
  .newmain{
    align-items: start;
  }
  .background-area{
    padding: 0;
    align-items: start;
  }
  .google-btn,.newinput-u{
    max-width: 100%;
    width:100%;
  }
}
@media (max-width:430px) {
  .readylinkbox {
      width: 100%;
  }
}


/* _____________________ */



.chips_input {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.chips_input >.inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.75rem;
  position: relative;
}
.tag-append-area{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.green-on{
  border-radius: 1px;
  background: #0BE714;
  width: 5px;
  height: 5px;
}
.tag-box-u{
  display: inline-flex;
  min-height: 20px;
  padding:4px 8px;
  align-items: center;
  gap: 5px;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.10);
  position: relative;
  word-break: break-word;
}
.tag-name-u{
  color: #333;
  font-family: GT Walsheim Pro;
  font-size: 13px;
  font-style: normal;
  font-weight:400;
  letter-spacing: -0.3px;
  pointer-events: none;
}
.remove-u {
  border: none;
  background: transparent;
  color: transparent;
  position: absolute;
  top: -5px;
  right: -3px;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.remove-u>i {
  cursor: pointer;
  color: #000;
  font-size: 12px;  
}
.tag-box-u:hover .remove-u{
  opacity: 1;
}
.tag-addinput{
  position: relative;
}
.act-inp{
  width: fit-content;
  display: flex;
  height: 20px;
  padding: 8px;
  align-items: center;
  gap: 5px;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.10);
  color: #333;
  text-align: center;
  font-family: GT Walsheim Pro;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.3px;
  gap: 5px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
-ms-user-select: none; /* IE 10 and IE 11 */
user-select: none; /* Standard syntax */
}
.input-box-u{
  width: 145px;
  border-radius: 9px;
  background: #FFF;
  padding: 3px 3px 3px 8px;
  align-items: center;
  position: absolute;
  bottom: -1.7rem;
  left: 0;
  display: none;
}
.input-activer{
  display: flex;
}
.tag-input-u{
  width: 100%;
  border: none;
  outline: none;
  z-index: 1;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.36px;
  padding-right: 6px;
}

span.limit {
  float: right;
}
.tagadd-btn{
  width: 47px;
  height: 24px;
  border-radius: 6px;
  background: #18F;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border: none;
}
.theme-selection-div.selected-theme{
  border: 2px solid #FF4566 !important;
  padding: 0;
  border-radius: 50%;
  height: 38px;
}
.plan-card-free {
  width: 100%;
  padding: 11px 15px 19px 15px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.37);
  background: linear-gradient(180deg, #1D2329 0%, #0F1314 100%);
  box-shadow: 0px 0px 50px rgba(90, 93, 121, 0.1);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}
.plan-asterisk {
  width: 14px;
  height: 14px;
  color: #FFF;
  font-size: 15.273px;
  font-weight: 500;
  line-height: 119.2%;
  border-radius: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 1px;
  cursor: pointer;
}
.feature-list-text:has(.project-tooltip){
  display: flex;
  align-items: center;
  gap: 2.5px;
}
.plan-tooltip .project-tooltip-content-arrow {
  display: none !important;
}
.plan-tooltip {
  background: #1D2228 !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16) !important;
  border: none !important;
  max-width: 256px !important;
  padding: 20px 15px 20px 16px !important;
}
.plan-tooltip .msg {
  color: #FFF !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1.3 !important;
  letter-spacing: -0.18px !important;
  text-wrap: wrap;
}
.red-button {
  background-color: #FF3434;
}
.filter-hue-142 {
  filter: hue-rotate(142deg) !important;
}
.red-box-shadow {
  box-shadow: 0 0 15px #ff34346e;
}
.plan-2nd-row {
  display: flex;
  gap: 15px;
}
.plan-2nd-row .plan-card{
  flex-basis: 45%;
}
.grey-dropdown {
  border-radius: 9px;
  background: #3A3A3A;
  padding: 8px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  border: none;
  cursor: pointer;
  width: fit-content;
}
.grey-dropdown::after {
  content: "";
  background-image: url('../assets/img/products/arrowicon1.svg');
  height: 9px;
  width: 15px;
  background-repeat: no-repeat;
  margin-top: 3px;
  margin-left: 15px;
  display: inline-block;
}
.grey-dropdown-menu {
  border-radius: 9px;
  background: #3A3A3A;
  padding: 2px 0px !important;
}
.grey-dropdown-item {
  padding: 4px 6px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 0px;
}
.grey-dropdown-item:hover{
  background: #585858 !important;
  color: #FFF !important;
}

.passwordInputFieldWrapper{
  position: relative;
}

.passwordInputField{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}

.passwordInputField input{
  width:100%;
  flex-basis: 100%;
  padding-right: 25px;
}

.passwordInputField  button{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-top: -2px;
}

.choosePlanBox{
  padding-bottom: 40px !important;
}

.reset-password-box{
  padding: 15px;
}

.reset-password-box .steps-card-width{
  width:100%;
  max-width: 450px;
  border-radius: 20px;
  background: #FFF;
  padding: 30px !important;
  margin:  0 auto;
}