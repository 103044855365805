#wrapper {
  margin: 0 auto;
  /* max-width: 1520px; */
}

.move-cursor{
  cursor: default;
}

#wrapper header {
  width: 100%;
  border-bottom: 1px solid rgba(12, 2, 43, 0.1);
  padding: 10px 15px;
  height: 60px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 99;
}

#wrapper header .header-dropdown-main {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 48px !important;
  height: 30px !important;
}

#wrapper header .header-dropdown-main .header-dropdown {
  padding: 8px 12px;
  box-shadow: 0px 8px 13px rgba(86, 85, 106, 0.07);
  border-radius: 10px;
  transition: all 0.2s ease;
  cursor: pointer;
  width: fit-content;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

#wrapper header .header-dropdown-main .header-dropdown:hover {
  box-shadow: 0px 8px 13px rgba(86, 85, 106, 0.15);
}

#wrapper header .header-dropdown-main .header-dropdown:after {
  display: none;
}

#wrapper header .header-dropdown-main .header-dropdown #header-chevron-down {
  transition: all 0.2s ease;
}

/* #wrapper header .header-dropdown-main .header-dropdown.active #header-chevron-down {
  transform: rotate(180deg);
} */

#wrapper .header-links-main {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  padding: 15px 10px;
}

#wrapper .header-links {
  display: flex;
  align-items: center;
  justify-content: start;
}
div#header-share-dropdown{
  font-size: 12px;
}
div#header-share-dropdown:after{
  display: none !important;
}
#wrapper .header-links .header-link {
  background-color: #F3F3F3;
  color: #333333;
  padding: 8px 15px;
  font-weight: 500;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
}

#wrapper .top-header-link {
  height: 30px !important;
  width: 30px ;
  background-color: #F3F3F3;
  color: #333333;
  /* padding: 11px 15px; */
  font-weight: 500;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  cursor: pointer;
  text-decoration: none;
  /* gap: 6px; */
}

#wrapper .header-links .header-link:hover,
#wrapper .top-header-link:hover {
  color: white;
  background-color: #18F;
}

#wrapper .header-links .header-link:hover svg path,
#wrapper .top-header-link:hover svg path {
  stroke: white !important;
}

#wrapper .header-links .header-link.active,
#wrapper .top-header-link.active {
  color: white;
  background-color: #18F;
}

#wrapper .header-links .header-link.active svg path,
#wrapper .top-header-link.active svg path {
  stroke: white !important;
}

#wrapper .dropdown-menu, .zaap-dropdown-menu {
  font-size: 14px;
  font-weight: 500;
  border-radius: 9px;
  box-shadow: 0px 4px 22px rgba(87, 88, 111, 0.13);
  border: none;
  margin-top: 10px !important;
  /* padding: 0.5rem; */
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  /* width: 181px; */
}

#wrapper .dropdown-menu li a, .zaap-dropdown-menu li a {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 9px;
}

#wrapper .dropdown-menu li a:hover {
  background-color: #1188ff;
  color: #FFFFFF;
}

#wrapper .dropdown-menu li a:hover svg path {
  fill: #FFFFFF;
}

#wrapper .dropdown-menu li .theme-active {
  background-color: #FFDFDF !important;
  color: #FF2020 !important;
}

#wrapper .dropdown-menu li .theme-active svg path {
  fill: #FF2020 !important;
}

#wrapper .dropdown-menu .dropdown-divider {
  border-top: 2px solid white !important;
}

.semi-bold {
  font-weight: 600;
}

.text-muted {
  color: white !important;
  width: 100%;

}

.text-muted-light {
  color: #9F9F9F !important;
  text-decoration: none;
  margin-top: -5px;
}

/* .text-muted-light:hover {
  color: #8C8E9C;
  text-decoration: none;
} */

.text-gray {
  color: #5B5B5B !important;
}

.text-lightgray {
  color: #6B6B6B !important;
}

.main-courser {
  color: white !important;
}

.zaap-badge {
  padding: 1px 10px;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
}

.badge-free {
  color: #118EFB;
  background-color: #E6F3FF;
}

.badge-pro {
  color: #fff !important;
  background: linear-gradient(97.2deg, #FFC122 0%, #FF7F23 100%) !important;
}

.copy-clipboard {
  cursor: pointer;
}

.btn {
  border-radius: 20px !important;
}

.btn-radius-10 {
  border-radius: 10px !important;
}

.btn-radius-12 {
  border-radius: 12px !important;
}

.py-01 {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}

.btn-primary {
  background-color: #FF1451 !important;
  color: white !important;
  border-color: #FF1451 !important;
}

.btn-primary:hover {
  background-color: #e40641 !important;
  border-color: #e40641 !important;
}

.btn-primary:active {
  background-color: #e40641 !important;
  border-color: #e40641 !important;
  box-shadow: 0 0 0 0.25rem rgba(228, 6, 65, 0.5) !important;
}

.btn-primary:focus {
  background-color: #e40641 !important;
  border-color: #e40641 !important;
  box-shadow: 0 0 0 0.25rem rgba(228, 6, 65, 0.5) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff !important;
  background-color: #FF1451 !important;
  border-color: #FF1451 !important;
}

.text-primary {
  color: #FF1451 !important;
}

.btn-block {
  width: 100% !important;
  font-size: 20px !important;
}

.upgrade-main-btn {
  /* text-transform: uppercase !important; */
  /* background: linear-gradient(to bottom right, #FFC122 0%, #FF7F23 100%) !important; */
  background-color: #1188FF !important;
  border-radius: 8px !important;
  color: #FFFFFF !important;
  padding: 8px 10px !important;
  font-weight: 500 !important;
  font-size: 12px;
  gap: 5px !important;
  box-shadow: 0px 4px 8px 0px rgba(17, 136, 255, 0.30) !important;
  height: 30px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 30px !important;
  /* letter-spacing: 1px !important; */
}

.upgrade-main-btn:hover {
  color: #FFFFFF !important;
}

h1,
h3,
h4,
h5 {
  font-weight: 600;
}

.py-25 {
  padding-top: 0.75em !important;
  padding-bottom: 0.75em !important;
}

input:-webkit-autofill {
  /* -webkit-text-fill-color: red !important; */
  -webkit-box-shadow: 0 0 0 30px #F6F6F6 inset !important;
  color: rgba(14, 18, 59, 0.5) !important;
}

/* Chrome, Edge, Safari */
.webkitScrollbar{
  scrollbar-width: thin; /* Firefox */
}
.webkitScrollbar::-webkit-scrollbar {
  width: 4px;
}
.webkitScrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* thumb color */
  border-radius: 4px;
}
.webkitScrollbar::-webkit-scrollbar-track {
  background: transparent; /* or any track color */
}

/* body.modal-open #wrapper div,
body.modal-open #wrapper button {
  -moz-filter: blur(1.5px);
  -o-filter: blur(1.5px);
  -ms-filter: blur(1.5px);
  filter: blur(1.5px);
}

body.teams-modal-open #wrapper div,
body.teams-modal-open #wrapper button {
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
}

body.teams-modal-open .modal {
  width: 75%;
  left: unset;
  right: 0;
  height: calc(100vh - 70px);
  top: unset;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
} */

.modal-content {
  border-radius: 1.2rem !important;
  /* overflow: hidden; */
}

#customizePageModal .modal-content {
  overflow: hidden;
}

.modal-border-straight {
  border-radius: 0 !important;
}

.modal-content.overflow-none {
  overflow: visible;
}

.modal-content.modal-semi-curve {
  border-radius: 1.2rem !important;
}

.modal-header {
  padding: 15px 25px;
  /* padding-bottom: 0.2rem; */
  border-bottom: 1px solid #E8E9EA;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  background-color: transparent;
  border: 0;
}

#upgradeTeamModal .modal-content {
  border: none !important;
}

.dropzone-wrapper {
  border: 2px dashed #585858;
  position: relative;
  height: 200px;
  border-radius: 8px;
  padding: 1px;
  background: #FFFFF9;
}

.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  top: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dropzone-desc p {
  font-size: 18px;
}

.dropzone-desc small {
  color: #6B6B6B;
}

.dropzone,
.dropzone:focus {
  background: #ffffff !important;
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #ecf0f5;
}

.full-page-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 111111;
  display: flex;
  /* padding-top: 20%; */
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.lds-ripple {
  width: 66px;
  height: 66px;
  border: 5px solid #ff1954;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* 
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #FF1451;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
} */

.dev_upgrade_new_main {
  background: #F7F7F8;
  border-radius: 14px;
  color: #3D404C;
  width: 100%;
}

.dv_upgrade_new {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dv_upgrade_new img {
  width: 1.8rem;
  height: auto;
  text-align: center;
}

.dev_upgrade_new_main h5 {
  font-size: 18px;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.cursor-pointer {
  cursor: pointer;
  /* color: white; */
}

.btn-grey {
  background-color: #F1F1F1 !important;
}
.btn-grey:hover{
  color: black;
}
.btn-semi-rounded {
  border-radius: 12px !important;
}

.btn-grey.btn-rounded {
  font-size: 16px;
  border: 1px solid #D0D5DD !important;
  background-color: white !important;
}

.rotate180 {
  transform: rotate(180deg);
}
.rotate45 {
  transform: rotate(45deg);
}
.rotate90 {
  transform: rotate(90deg);
}
.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.btn:focus,
.btn:active,
.btn:visited {
  box-shadow: none !important;
}

.bg-primary-dark {
  background-color: #CF2551;
}

.btn-light-shadow {
  box-shadow: 0px 4px 15px rgba(35, 35, 68, 0.1);
}

.bg-f1f1f1 {
  background: #F7F7F8 !important;
}

.resize-none {
  resize: none !important;
}

.zaap-form-control {
  border-radius: 12px !important;
  border: 1px solid #E1E1E1 !important;
  color: black !important;
  font-weight: 500;
  padding: 10px 15px !important;
  height: auto;
}

.zaap-form-controls {
  border-radius: 12px !important;
  border: 1px solid #E1E1E1 !important;
  color: black !important;
  font-weight: 500;
  height: 48px;
  padding: 3px;
  width: 100%;
  margin-top: 7px;
}

.zaap-form-control::-moz-placeholder {
  color: #CDCDCD !important;
  font-weight: 400;
}

.zaap-form-control:-ms-input-placeholder {
  color: #CDCDCD !important;
  font-weight: 400;
}

.zaap-form-control::placeholder {
  color: #CDCDCD !important;
  font-weight: 400;
}

.zaap-form-control:active,
.zaap-form-control:focus {
  box-shadow: 0 0 0 0.05rem rgba(0, 0, 0, 0.05);
}

.borderless {
  border: none !important;
  box-shadow: none !important;
}

.btn-light {
  background-color: #F3F3F3;
}

.analytics-table-pagination>.btn-light {
  background-color: #F3F3F3 !important;
  margin-right: 2px;
  padding: 0.25rem 0.75rem;
}

.analytics-table-pagination>.btn-light.active {
  background-color: #F3F3F3 !important;
  /* color: #F3F3F3; */
}

.sortable-col {
  cursor: pointer;
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.sortable-col-desc {
  background-color: #F3F3F3 !important;
}

.sortable-col-desc img {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.zaap-border-none{
  border: none !important;
}
.zaap-border-none:hover{
  border: none !important;
}
.zaap-border-straight {
  border: none;
  border-radius: 0px !important;
}

.zaap-border-rounded {
  border-radius: 12px !important;
}

.zaap-border-rounded.border-radius-10 {
  border-radius: 10px !important;
}

.zaap-border-rounded-left {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.zaap-border-rounded-right {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.accordion::-webkit-scrollbar {
  width: 0em;
}

.accordion-button::after {
  background-image: url("../assets/img/modals/chevron-down.svg") !important;
  width: 15px;
  height: 15px;
  background-size: 15px;
  margin-top: 12px;
}

.accordion-button img:not(.collapsed)::after {
  transform: rotate(180deg) translateY(8px) !important;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(180deg) !important;
}

.accordion-button img:not(.collapsed) {
  background-color: #F1F1F1 !important;
  color: inherit !important;
}

.accordion-button img {
  box-shadow: 0px 4px 20px rgba(103, 101, 122, 0.19);
  border-radius: 10px;
}

.bg-gray {
  background-color: #E8E9EA !important;
}

.bg-f3f3f3 {
  background-color: #F3F3F3 !important;
}

.text-red {
  color: #FF044F !important;
}

.decoration-none {
  text-decoration: none !important;
}

.form-control[readonly] {
  background-color: #fbfbfb !important;
}

.form-group-disabled label {
  color: #CDCDCD !important;
}

.form-group-disabled label small {
  color: #CDCDCD !important;
}

.email-blocks-main-template-field {
  width: 100%;
  /* height: 330px; */
  /* background: red; */
  padding: 10px;
}

.email-blocks-dropdown-template-field {
  width: 220px;
  height: 100%;
  background-color: white;
  border-radius: 30px;
  padding: 20px 0px;
  box-shadow: 0px 0px 50px rgba(6, 20, 40, 0.05);
}

.email-blocks-block-template-field {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: start;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.email-blocks-block-template-field:hover {
  background-color: #F2F3F5;
}

.email-blocks-block-svg-template-field {
  width: 40px;
}

#email-body-h1-tag {
  font-size: 32px !important;
  border: none;
  outline: none;
  color: #000;
  width: 95%;
  font-weight: bold;
  background-color: transparent;
}

#email-body-h1-tag::-moz-placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-h1-tag:-ms-input-placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-h1-tag::placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-h2-tag {
  font-size: 22px !important;
  border: none;
  outline: none;
  color: #000;
  width: 95%;
  font-weight: bold;
  background-color: transparent;
}

#email-body-h2-tag::-moz-placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-h2-tag:-ms-input-placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-h2-tag::placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-divider-tag {
  height: 1px;
  width: 95%;
  background: rgba(3, 3, 22, 0.3);
}

#email-body-image-tag {
  background-color: white;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 20px;
  color: rgba(14, 18, 59, 0.5);
  box-shadow: 0px 0px 10px rgba(6, 20, 40, 0.1);
}

#email-body-p-tag {
  font-size: 14px !important;
  border: none;
  outline: none;
  color: #000;
  width: 95%;
  font-weight: bold;
  background-color: transparent;
}

#email-body-p-tag::-moz-placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-p-tag:-ms-input-placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-p-tag::placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-button-tag {
  font-size: 14px !important;
  border: none;
  outline: none;
  color: #FFF !important;
  padding: 10px 10px;
  min-width: 194px;
  border-radius: 50px;
  background-color: #FF1451;
  /* margin:0 auto; */
  text-align: center;
  font-weight: bold;
  flex-basis: auto;
}

#email-body-button-link-tag {
  font-size: 14px !important;
  border: none;
  outline: none;
  color: #000;
  width: 95%;
  font-weight: bold;
  background-color: transparent;
}

#email-body-button-link-tag::-moz-placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-button-link-tag:-ms-input-placeholder {
  color: rgba(14, 18, 59, 0.25);
}

#email-body-button-link-tag::placeholder {
  color: rgba(14, 18, 59, 0.25);
}

.product-description-dropdown-menu {
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(87, 88, 111, 0.13);
  border: none;
  margin-top: -30px !important;
  padding: 0.5rem;
}

.opacity-0 {
  opacity: 0 !important;
}

/*# sourceMappingURL=style.css.map */

.dropdown-menu li a {
  cursor: pointer;
}

/* edit link */
.banner-upload-btn,
.banner-upload-btn2 {
  color: black;
  font-weight: 600;
  border: 1px solid rgba(14, 18, 59, 0.2);
  box-sizing: border-box;
  border-radius: 13px;
  height: 46px;
}

.banners-main {
  width: 100%;
  height: 300px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  /* background-color: red; */
  align-items: center;
  padding: 10px 20px;
}

.banner-div {
  flex-basis: 33.33%;
  padding: 4px;
  cursor: pointer;
}

.banner-div img {
  width: 100%;
  border-radius: 7px;
  border: 3px solid transparent;
  background-color: #EDEDED;
}

.banner-div.active img {
  border: 3px solid #FF1451;
}

/* acccouts */
/* .account-form {
  background: white;
  border-radius: 35px;
  width: 100%;
} */

.acount-header {
  /* padding-bottom: 20px;
  border-bottom: 1px solid rgba(14, 18, 59, 0.1);
  margin-bottom: 30px; */
  font-size: 24px;
  margin-bottom: 1.5rem;
  margin-top: 1rem!important;
}

/* .account-form label {
  color: rgba(14, 18, 59, 0.25);
  font-weight: bold;
} */

.account-form .form-control {
  border: none;
  background: #EEEDEE;
  font-weight: 700;
  color: #5B5B5B !important;
  border-radius: 9px;
  height: 50px;
}
.account-form .form-control:focus {
  box-shadow:none;
  border:1px solid #FF1451;
}
.account-form .btn-primary {
  border-radius: 18px ;
  padding: 10px;
}

.text-color-img {
  border: 2px solid transparent;
  /* border-radius: 50px; */
  cursor: pointer;
}

/* .text-color-img.active {
  border: 2px solid #ff1451 !important;
} */

.ce-popover {
  margin-left: 4px
}

.modal-body-main {
  overflow-x: hidden !important;
}

@media screen and(max-width:768px) {

}

@media (min-width: 425px) {
  .product-style {
    width: 50% !important
  }
}
/* .ce-popover {
  margin-left: -129px
} */
.free-plan-text{
  text-align: center;
}

.free-plan-text button{
  text-decoration: none;
  color: #676677;
  font-weight: 500;
  border : none !important;
  background: none !important;
  /* padding-bottom: 115px !important; */
}
.free-plan-text span{
  border-bottom: 1px solid #676677 ;
  border-style: dashed;
  border-top: none;
  border-left: none;
  border-right: none;
}
.project-dropdown-main {
  display: flex;
  align-items: center;
  justify-content: start;
}

 .project-dropdown {
  padding: 8px 12px;
  border-radius: 10px;
  transition: all 0.2s ease;
  cursor: pointer;
}
#project-chevron-down {
  transition: all 0.2s ease;
}

.project-dropdown.active #project-chevron-down {
  transform: rotate(180deg);
}
.project-dropdown-menu{
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(87, 88, 111, 0.13);
  border: none;
  margin-top: 10px !important;
  padding: 7px 5px 7px 7px 7px !important;
  min-width:229px;
  margin-bottom: 2px;
  
}
 .project-dropdown:hover {
  box-shadow: 0px 8px 13px rgba(86, 85, 106, 0.15);
}

 .project-dropdown:after {
  display: none;
}

 .project-dropdown #project-chevron-down {
  transition: all 0.2s ease;
}

 .project-dropdown.active #project-chevron-down {
  transform: rotate(180deg);
}
.project-dropdown-item{
  padding: 4px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 9px !important;
}
.bottom-navbar {
  display: none !important;
  /* overflow: hidden; */
  background-color: #f7f7f7;
  position: fixed;
  bottom: 0;
  width: 100%;
  /* display: flex; */
  justify-content: space-between;
  padding: 7px 18px;
  z-index: 15;
  align-items: center;
  z-index: 100;
}

.bottom-navbar .navbar-items {
  display: flex;
  overflow-x: auto;
  gap: 2px;
}
.bottom-navbar .navbar-items::-webkit-scrollbar {
  -ms-overflow-style: none;  
  scrollbar-width: none;
  display: none;
}
.bottom-navbar a {
  float: left;
  display: flex;
  color: #f2f2f2;
  text-align: center;
  padding: 6px 8px 8px;
  text-decoration: none;
  font-size: 17px;
  min-width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.bottom-navbar a svg, .bottom-navbar a img{
  max-width: 17px;
  max-height: 17px;
  object-fit: cover;
  width: 17px;
  height: 17px;
}

.bottom-navbar a:hover {
  background: #f1f1f1;
  color: white;
}

.bottom-navbar a.active {
  background-color: #1188FF;
  color: black;
  border-radius: 8px;
}
.bottom-navbar a.active svg path{
  stroke: white ;
}
.bottom-navbar a.active img{
  filter: invert(100%);
}

.bottom-navbar .project-dropdown {
  padding: 4px;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-right: 0px;
  border-radius: 100%;
  position: relative;
  background: #eee;
}

.bottom-navbar .project-dropdown img{
  width: 30px;
  height: 30px;
  object-fit: cover;
}

@media (max-width: 459px) {

}
#wrapper .settings-section{
    padding: 26px 28px 30px 28px;
    max-width: 600px;
    background: #FAFAFA;
    /* flex-basis: 50%; */
    justify-content: center;
    display: flex;
    /* margin-left: 89px; */
    justify-content: start;
    /* align-items: center; */
    flex-direction: column;
    margin-top: 50px;
    border-radius: 16px;
}
 .project-types{
  padding: 8px 12px;
  border-radius: 7px;
  color:white;
  font-size: 10px;
  height: 23px;
  display: inline-flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform:capitalize ;
}
 .project-types.ultra{
  background: #FFB502;
  box-shadow: 0px 8px 20px 0px rgba(255, 182, 44, 0.20);
}
 .project-types.pro{
  box-shadow: 0px 8px 20px 0px rgba(17, 136, 255, 0.20);
  background: #18F;

}
.project-types.partner{
  box-shadow: 0px 8px 20px 0px rgba(174, 2, 255, 0.20);
  background-color:#AE02FF;
}
 .project-types.draft{
  color: #FFF;
  background: #5F5F5F !important;
}
.page-published{
  display: flex;
  align-items: center;
  justify-content: center;
  /* width:0px; */
  gap: 8px;
  margin-left: 148px !important;
}
#wrapper .left-side-bar.closed .account-div{
  height: 61px;
  width: 61px !important;
  border-radius: 16px !important;
  padding: 15px !important;
  margin: 0 !important;
}
#wrapper .left-side-bar.closed .account-div img {
  margin: 0 !important;
}
@media (max-width: 768px) {
  .settings-sidebar-for-mobile .toggle-button img{
    display: none !important;
  }
  .settings-sidebar-for-mobile.open.active{
   width: 100% !important;
  }
  .settings-sidebar-for-mobile.open{
    width:100% !important;
  }
  .settings-sidebar-for-mobile.closed{
    display:none !important;
  }
}
.side-bar-headings{
  color: #333;
  /* margin-top: 7px; */
  margin-bottom: 0.25rem;
  font-size: 14px;
  font-weight: 500;
}
.account-form  .account-header {
  font-size: 22px;
}
.settings-button-primary {
  padding: 0 10px !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  display: inline-flex ;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: none;
  box-shadow: none;
  flex-shrink:0;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none !important;
  color: #000;
}
.account-form  label {
  font-size: 12px;
  color:#333333;
}
.account-form .btn-primary small,.settings-button-primary small {
  display: flex;
  height: 13px;
  padding: 0px 7px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #CF2551;
}
.account-form  .form-control{
  height: 30px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.settings-connect-btn{
  display: inline-flex !important;
  height: 30px !important;
  padding: 10px 13px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 6px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 9px !important;
}
.settings-section-heading{
  font-size: 22px;
  color: #03021C;
  font-weight: 700;
}
.btn-add-link{
  display: inline-flex !important;
  height: 30px !important;
  padding: 11px 15px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 6px !important;
  border-radius: 9px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.add-new-project-btn{
  Font-size: 12px;
  font-weight: 500;
}
.manage-btn {
  display: inline-flex;
  height: 30px;
  padding: 0px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 9px;
  background: rgb(250, 250, 250);
  margin-right: 10px;
  font-size: 12px;
  /* background: #FAFAFA;*/
  color: #333; 
  font-weight: 500;
}

.manage-btn span{
  color: #333;
  font-size: 12px;
  font-weight: 500;
}

.manage-projects-text{
  font-size: 12px;
  color: #333;
  vertical-align: middle !important;
}
.editor-link-headings{
  color: #333;
  font-size: 18px;
  font-weight: 500;
  line-height: 106.1%;
  display: inline-block;
  width: 180px;
  /*white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;*/
}
.payout-buttons{
  font-weight: 500 !important;
  font-size: 12px !important;
  display: inline-flex;
  height: 30px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  background: #FAFAFA;
  border: none;
  letter-spacing: -0.18px;
  color: #000;
}
.team-members-icon{
  background-color: #FF1451;
  padding: 4px;
  border-radius: 9px;
  color: white;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.member-remove-btn{
  background-color: #FFDCDC;
  padding: 10px 13px;
  border-radius: 9px;
  border: none;
  color: #FFD7E1;
  color: #FF1451;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 30px;
}
.team-owner-btn{
  display: inline-flex;
  height: 30px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  background: #FAFAFA;
  border: none;
  font-size: 12px;
  font-weight: 500;
  color:#000;
}
.billings-data-div {
  background:#EEEDEE;
  border-radius:12px;
  height: 50px;
  font-size: 12px;
}
.page-published-slug {
  font-Size:12px;
  margin-Bottom:5px;
  height:12px;
  margin-Top:6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.domain-steps-label {
  color: #03021C;
  font-size: 12px;
  font-weight: 500;
}
.domain-first-step-fields{
  display: flex;
  justify-content: space-between;
}
.domain-first-step-fields labels{
  color: #6F6E7A;
}
.first-step-name-field{
  width: 28%;
}
.first-step-value-field{
  width: 45%;
}
.first-step-ttl-field{
  width:21%
}
.first-step-value-icon{
  position: absolute;
  right: 10px;
  top: 16%;
}
#checkbox-select-all {
  width: 16px;
  height: 16px;
}
.delete-all-btn {
  display: inline-flex;
  height: 26px;
  /* padding: 7px 12px; */
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 9px;
  background: #FFDFDF;
  color: #FF2020;
  font-size: 12px;
  font-weight: 500;
  border: none;
  text-wrap: nowrap;
  /* width: fit-content; */
}

/* unaiz cSS */
.modal-u{
  padding: 0px ;
  overflow: hidden;
}
.upgradeimg{
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;
}
.create_folder{
  padding: 1rem;
}



/* empty-state css */
.oval-container{
  width: 100%;
  /* height: calc(100dvh - 70px); */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 16px;
  background: #FAFAFA;
  position: absolute;
  inset: 0;
  /* margin-top: 21px; */
}
.oval4-u {
  width: 1122px;
  height: 1122px;
  flex-shrink: 0;
  border-radius: 1122px;
  border: 1px solid rgba(234, 234, 234, 0.00);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.05) 0%, rgba(129, 164, 255, 0.05) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.oval3-u {
  width: 928px;
  height: 928px;
  flex-shrink: 0;
  border-radius: 928px;
  border: 1px solid rgba(234, 234, 234, 0.00);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.05) 0%, rgba(129, 164, 255, 0.05) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.oval2-u{
  width: 743px;
  height: 743px;
  flex-shrink: 0;
  border-radius: 743px;
  border: 1px solid rgba(234, 234, 234, 0.00);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.05) 0%, rgba(129, 164, 255, 0.05) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.oval1-u {
  width: 437px;
  height: 437px;
  flex-shrink: 0;
  border-radius: 437px;
  border: 1px solid rgba(234, 234, 234, 0.00);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.05) 0%, rgba(129, 164, 255, 0.05) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 76px;
}
.oval-h2{
  color: #333;
  font-family: GT Walsheim Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 111%; /* 19.98px */
}
.oval-para{
  color: #333;
  text-align: center;
  font-family: GT Walsheim Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 111%; /* 15.54px */
  margin-top: 23px;
}
.btn-u-primical{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background-color: #18F;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border: none;
  outline: none;
  height: 30px;
  width: 96px;
  margin-top: 17px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-checkbox {
  display: inline-flex;
  position: relative;
  padding-left: 27px;
  margin-bottom: 13px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border-radius: 5px !important;
  box-shadow: 0px 4px 4px 0px rgba(17, 136, 255, 0.10);
  border: 1px solid #6B6B6B;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #18f;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5.588px;
  height: 8.941px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.share-dropdown-link{
  text-decoration: none;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  padding: 0 !important;
}
.share-dropdown-link:hover{
  background: none !important;
  color: #333 !important;
}
.close-u{
  z-index: 15;
  height: 10px;
  width: 10px;
}
.close-u>svg{
  margin-top: -10px;
}
.modal-header {
  padding: 18px 20px ;
}
.mt-u{
  margin-top: 24px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.mt-u>button {
  background: none;
  border: none;
  outline: none;
}
.mt-15 {
  margin-top: 15px !important;
  margin-bottom: 0px !important;
}
.block-nav-u{
  padding: 0 25px !important;
}
.mb-u{
  margin-bottom: 24px !important;
}
.uplod-btn-u{
  border-radius: 9px;
  background: #F3F3F3;
}
.upload-text{
  color: #333;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
}
.check-para{
  color: rgba(91, 91, 91, 0.50) !important;
}
.font-14-u{
  font-size: 14px !important;
  color: #333 !important;
}
.border-0 {
  border: 0!important;
}
.save-btn-u{
  font-size: 12px !important;
  border-radius: 9px !important;
  height: 30px;
}
@media (max-width: 670px) {
  .nav-tabs-menus {
    display: flex;
  }
  .custom-u{
    flex-direction: column-reverse;
  }
  .customize-page-desktop .left-bar {
    width: 100% !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    position: absolute;
    height: fit-content !important;
    bottom: 0;
    z-index: 6;
    padding: 14px 60px 14px 14px !important;
    overflow-x: auto !important;
    overflow: hidden;
  }
  .customize-page-desktop .main-section {
    width: 100% !important;
  }
  /* .custom-width-u{
    width: 111px !important;
  } */
  .fade-u {
    width: 100px;
    height: 84px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(90deg,#ffffff00,#ffffffcf,#ffffff);
    z-index: 20;
}
.customize-page-desktop .submit-buttons-fixed {
  position: static !important;
  background: transparent !important;
}
}

.ms-u{
  margin-left: 5px !important;
}
.customize-font-tabs input:checked + label{
  background: #0C8CFB !important;
  color:white !important;
}
.customize-font-tabs input:hover + label:hover{
  background: #ffffff;
}
.customize-font-tabs-background{
  background-color: #F7F7F8 !important;
  border-radius: 14px;
}
.cont-banner-area{
  margin-left: 21px;
}
.prof-u{
  width: 100px;
  padding:6px !important;
  background-color: #F6F6F6;
}
.profile-style-div {
  border-radius: 12px !important;
}
.ml-10-u{
  margin-left: 10px;
}
.newbanner-btn{
  margin-top: 11px;
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding: 7px 15px !important;
  width: fit-content !important;
  height: fit-content !important;
}
.bannerdef{
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: -2rem;
}
.u-control{
  border-radius: 9px !important;
  color: black !important;
  fill: black !important;
}
.banner-text{
  color: #333;
  font-family: GT Walsheim Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.1%; /* 14.854px */
}
.banner-area{
  display: flex;
  gap: 5px;
}
.banner-border{
  border: 2px dashed #333;
  border-radius: 12px;
}
.banner-div-u{
  width: 100px;
  padding: 2px !important;
  border-radius: 5px;
}
.zaap-normal-text {
  color: #333;
  font-size: 14px;
  font-weight: 500;
}
.color-picker-div {
  border-radius: 9px;
  background: #F2F2F2;
  padding: 5px;
  gap: 15px;
  cursor: pointer;
}
.color-picker-div span {
  width: 22px;
  height: 22px;
  border-radius: 7px;
  aspect-ratio: 1/1;
}
.colorinp-u{
  border-radius: 9px;
  background: #F2F2F2;
  width: 100%;
  padding: 8px 10px;
  margin-top: 10px;
}
.modal-u2{
  padding: 15px;
  min-width: 260px;
  width: 100%;
}
.color-picker-modal {
  width: 100%;
  position: absolute !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 9999 !important;
  inset: 0 !important;
}
.close-new{
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.10);
  display: inline-flex;
  height: 20px;
  padding: 8px;
  align-items: center;
  gap: 5px;
  border: none;
  outline: none;
}
.close-new>span{
  color: #333;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.3px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.posthead{
  color: #03021C;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 111%; /* 24.42px */
  letter-spacing: -0.33px;
}
.body-u{
  padding:16px 20px !important;
  scrollbar-width: auto;
  scrollbar-color: #797979 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .body-u::-webkit-scrollbar {
    width: 6px;
  }

  .body-u::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .body-u::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 9px;
    border: 0px solid #ffffff;
  }
.new-title-inp{
  border-radius: 9px !important;
  background: #EEEDEE !important;
  border: none !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.1%; /* 12.732px */
  letter-spacing: -0.18px;
  color: black;
}
.new-title-inp::placeholder{
  color: rgba(91, 91, 91, 0.50);
}
.text-drag{
  color: #5B5B5B;
  text-align: center; 
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.21px;
}
.text-drag-2{
  color: #9F9F9F;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.1%; /* 12.732px */
  letter-spacing: -0.18px;
  margin-top: 8px;
}
.time-u{
  color: #FF1451;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 130.1%; 
  letter-spacing: -0.15px;
}
.newbtn-u2{
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 114.1%;
  letter-spacing: -0.18px;
}
.add-tags-dropdown:after {
  display: none;
}

.audience-tags-dropdown:not(.audience-tags-dropdown-bulk){
  max-width: 181px;
  transform: translate(-169px, 42px) !important;
}
.audience-tags-dropdown .search-bar,.audience-tags-dropdown .search-bar:active{
  background-color: #E5E5E5 !important;
}
.audience-tags-dropdown .dropdown-item{
  border-radius: 9px;
}
.new-zaap-inputs{
  border-radius: 9px !important;
  background: #EEEDEE !important;
  border: none !important;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 15px !important;
}
.new-zaap-inputs::placeholder{
  color: rgba(91, 91, 91, 0.50) !important;
}
.new-zaap-labels{
  color: #333;
  font-size: 14px;
  font-weight: 500;
}
.new-zaap-para{
  color: #5B5B5B;
  font-size: 14px;
  font-weight: 500;
}
.new-zaap-head{
  color: #333;
  font-size: 22px;
  font-weight: 700;
}
.zaap-cancel-btn{
  color: #2D2D2D;
  font-size: 12px;
  font-weight: 500;
  margin-right: 9px;
}
.zaap-connect{
  font-size: 12px;
  font-weight: 500;
}
.typing-loader{
  margin: 106px auto;
  width: 6px;
  height: 10px;
  border-radius: 80%;
  -webkit-animation: line 1s linear infinite alternate;
 -moz-animation: line 1s linear infinite alternate;
 animation: line 1s linear infinite alternate;
}
@-webkit-keyframes line{
  0%{
     
      background-color: rgba(0,0,0, 1);
      box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                  24px 0px 0px 0px rgba(0,0,0,0.2);
                  
    }
  25%{ 
          background-color: rgba(0,0,0, 0.4);
          box-shadow: 12px 0px 0px 0px rgba(0,0,0,2), 
                  24px 0px 0px 0px rgba(0,0,0,0.2);
                  
  }
  75%{ background-color: rgba(0,0,0, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                  24px 0px 0px 0px rgba(0,0,0,2);
                 
    }
}

@-moz-keyframes line{
  0%{
     
      background-color: rgba(0,0,0, 1);
      box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                  24px 0px 0px 0px rgba(0,0,0,0.2);
                  
    }
  25%{ 
          background-color: rgba(0,0,0, 0.4);
          box-shadow: 12px 0px 0px 0px rgba(0,0,0,2), 
                  24px 0px 0px 0px rgba(0,0,0,0.2);
                  
  }
  75%{ background-color: rgba(0,0,0, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                  24px 0px 0px 0px rgba(0,0,0,2);
                 
    }
}

@keyframes line{
  0%{
     
      background-color: rgba(0,0,0, 1);
      box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                  24px 0px 0px 0px rgba(0,0,0,0.2);
                  
    }
  25%{ 
          background-color: rgba(0,0,0, 0.4);
          box-shadow: 12px 0px 0px 0px rgba(0,0,0,2), 
                  24px 0px 0px 0px rgba(0,0,0,0.2);
                  
  }
  75%{ background-color: rgba(0,0,0, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                  24px 0px 0px 0px rgba(0,0,0,2);
                 
    }
}
a.share-dropdown-text:hover{
  color: #333 !important;
  background: none !important;
}
a.share-dropdown-text{
  text-decoration: none !important;
  color: #333 !important;
}
@media (max-width:445px) {
    .editor-link-headings {
      width: 115px;
  }
}
@media (max-width:375px) {
  .editor-link-headings {
    width: 80px;
}
}
.small-box {
  width: 5px !important;
  height: 5px !important;
  border-radius: 1px !important;
}
.tags-option{
    display: flex;
    gap: 5px;
    align-items: center;
}

/*Checkboxes styles*/
.tag-checked[type="checkbox"] { display: none; }

.tag-checked[type="checkbox"] + label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.tag-checked[type="checkbox"] + label:last-child { margin-bottom: 0; }

.tag-checked[type="checkbox"] + label:before {
  content: '';
  display: block;
  width: 17px;
  height: 17px;
}

.tag-checked[type="checkbox"]:checked + label:before {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><rect x="2.21002" y="8.87524" width="6.65878" height="2.038" rx="1.019" transform="rotate(33.1196 2.21002 8.87524)" fill="black"/><rect x="13.5775" y="3.63867" width="12.6356" height="2.038" rx="1.019" transform="rotate(123.12 13.5775 3.63867)" fill="black"/></svg>');
}
.tag-checked[type="checkbox"]:checked + label {
  background-color: transparent !important;
  margin-left: -4px;
  margin-bottom: 10px;
}
.checked-tag{
  margin-left: 6px;
}
.status-badge{
  display: inline-flex;
  height: 25px;
  padding: 8px 13px;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.36px;
}
.tags-badge {
  display: inline-flex;
  height: 20px;
  padding: 8px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.10);
  color: #333;
  font-size: 10px;
  font-weight: 500;
  line-height: 111%; /* 11.1px */
  letter-spacing: -0.3px;
}
.add-tags-dropdown-bulk{
  border-radius: 9px;
  background: #EEEDEE;
  display: inline-flex;
  height: 30px;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}
.side-bar-menus {
  overflow-y: auto;
}
.side-bar-menus::-webkit-scrollbar {
  -ms-overflow-style: none;  
  scrollbar-width: none;
  display: none;
}
.add-products-dropdown::after{
  display: none;
}
.selected-products {
  background-color: rgba(0, 0, 0, 0.10);
  margin-left: 4px;
  border-radius: 7px;
  padding: 2px 8px;
  font-size: 11px;
}
.code-products-dropdown {
  /* transform: translate(27px, 467px) !important; */
  border-radius: 15px;
  overflow-x: hidden;
}
.hidden-scroll::-webkit-scrollbar {
  -ms-overflow-style: none;  
  scrollbar-width: none;
  display: none;
}
.page-theme-preview {
  border-radius: 12px;
  background: #1D4953;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.03) inset, 0px 2px 10px 0px rgba(0, 0, 0, 0.03) inset;
  width: 100%;
  height: 195px;
  padding-top: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}
.page-theme-preview .page-title-preview {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
.page-silder-preview {
  display: flex;
  gap:6px;
  overflow-x: scroll;
}
.page-link-preview{
    border-radius: 12px;
    background-color: #133138;
    width: 104px;
    height: 136px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    gap: 6px;

}
.page-image-preview {
  width: 92px;
  height: 56px;
  border-radius: 7px !important;
  background: #FFF;
}
.page-text-preview {
  border-radius: 26px;
  background: #FFF;
}
.page-heading-preview {
  height: 12px;
  width: 78px;
}
.page-description-preview {
  width: 64px;
  height: 12px;
  margin-top: 2px;
}
.page-save-preview {
  width: 45px;
  height: 15px;
}
.theme-type-div {
  margin-top: 30px;
  margin-bottom: 15px;
}
.selected-theme-option.active {
  border-radius: 15px;
  border: 1px solid #4184D6;
  padding: 2px;
}
.custom-theme-type.disabled>div {
  opacity: 0.5;
}
.custom-theme-type.disabled {
  position: relative;
}
.theme-type-div-heading {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 111%; /* 15.54px */
}
.theme-type-div-des {
  color: rgba(91, 91, 91, 0.50);
  font-size: 12px;
  font-weight: 500;
  line-height: 106.1%;
}
.theme-type-selection {
  border-radius: 14px !important;
  border: 1px solid #E3E2E7;
  background: #FFF;
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  height: 50px;
  position: relative;
  cursor: pointer;
}
.theme-upgrade-main-btn  {
  padding: 6px 8px;
  border-radius: 9px;
  background: #18F;
  color: #FFF;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  border: none !important;
  position: absolute;
  top: -16px;
  right: 17px;
  z-index: 1;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.19);
}
.hidden-radio-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.page-preview-link {
  background: #F6F6F6;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.03) inset, 0px 2px 10px 0px rgba(0, 0, 0, 0.03) inset;
}
.page-silder-preview .page-link-preview:nth-child(1) .link-dummy-image {
  background-color: #7735BC;
}
.page-silder-preview .page-link-preview:nth-child(2) .link-dummy-image {
  background-color: #4DAEF5;
}
.page-silder-preview .page-link-preview:nth-child(3) .link-dummy-image {
  background-color: #ED2642;
}
.page-silder-preview .plain-link-preview:nth-child(1) {
  background-color: #9B59E0;
}
.page-silder-preview .plain-link-preview:nth-child(2) {
  background-color: #70C3FF;
}
.page-silder-preview .plain-link-preview:nth-child(3) {
  background-color: #FD5169;
}
.blue-bg {
  width: 30px;
  height: 30px;
  border-radius: 9px;
  background: #0C8DFB;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blue-bg .soft {
  border-radius: 4px;
  border: 1.5px solid #FFF;
  filter: blur(1px);
  width: 16px;
  height: 16px;
}
.blue-bg .hard {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1.5px solid #FFF;
}
.design-branding-section {
  border-radius: 12px;
  background: #F6F6F6;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.03) inset, 0px 2px 10px 0px rgba(0, 0, 0, 0.03) inset;
  position: relative;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.branding-footer{
  border-radius: 21.5px;
  background: #1A1A1A;
  width: 163px;
  height: 43px;
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%; /* 18px */
  display: flex;
  justify-content: center;
  align-items: center;
}
.design-branding-section .theme-upgrade-main-btn,.page-preview-bg .theme-upgrade-main-btn{
  top: 10px;
  right: 10px;
}
.page-preview-bg{
  position: relative;
  max-width: 546px;
  height: 140px;
  border-radius: 12px;
  background: #F6F6F6;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.03) inset, 0px 2px 10px 0px rgba(0, 0, 0, 0.03) inset;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}
.page-preview-bg .main-div {
  border-radius: 14px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: #33362F;
  width: 242px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
}
.page-preview-bg .center-div{
  z-index: 5;
}
.page-preview-bg .left-div {
  left: 26px;
  top: 45px;
}
.page-preview-bg .right-div {
  right: 27px;
  top: 45px;
}
.dummy-profile {
  width: 61px;
  height: 61px;
  background-color: #1D1D1D;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(227 222 222 / 22%);
}
.banner-preview-bg .main-div {
  border: none;
}
.banner-preview {
  border-radius: 12px 12px 0 0;
  background: #D9D9D9;
  width: 100%;
  height: 59px;
  flex-basis: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
}

.banner-preview-bg .main-div .profile-pic{
  z-index: 2;
}
.banner-preview-bg .left-div {
  transform: rotate(357deg);
  left: 0;
  width: 183px;
}
.banner-preview-bg .right-div {
  right: 0;
  width: 183px;
  transform: rotate(4deg);
}
.banner-preview-bg .center-div{
  z-index: 5;
}
.theme-type-div .upload-pp-btn {
  padding: 10px;
}
.design-content-divider{
  border-radius: 10.877px;
  border: 1px solid #F3F3F3;
}
.customize-page-main>h4 {
  color: #03021C;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 111%; /* 24.42px */
}
.affiliate-program-page-container {
  padding: 30px !important;
  max-width: 619px !important;
  width: 100%;
  margin-top: 0 !important;
}
.affiliate-program-page-heading {
  color: #03021C;
  font-size: 18px;
  font-weight: 500;
  line-height: 111%;
}
.affiliate-program-page-desc {
  color: #333;
  font-size: 12px;
  font-weight: 500;
  line-height: 130.1%; /* 15.612px */
  letter-spacing: -0.18px;
  margin-top: 10px;
}
.affiliate-program-page-analytics {
  width: 180px;
  min-height: 60px;
  border-radius: 10px;
  background: #FFF;
  display: flex;
  padding: 15px;
  gap: 15px;
}
.affiliate-analytics-heading{
  color: #5B5B5B;
  font-size: 12px;
  font-weight: 500;
  line-height: 121%; /* 14.52px */
  letter-spacing: -0.12px;
}
.affiliate-analytics-stats{
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 121%; /* 19.36px */
  letter-spacing: -0.16px;
}
.recent-referral-header {
  background: #EBEBEB;
  color: #5B5B5B;
}
.recent-referral-tabs{
  padding: 10px;
  max-width: 559px;
  height: 35px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 121%; /* 14.52px */
  letter-spacing: -0.12px;
  margin-top: 14px;
}
.recent-referrals-end-area {
  display: flex;
  gap: 69px;
  margin-right: 10px;
  align-items: center;
}
.referral-monthly-revenue {
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  display: inline-flex;
  height: 25px;
  padding: 8px 13px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
}
.recent-referral-last-column-heading {
  display: flex;
  gap: 34px;
  margin-right: 47px;
}

.affiliate-payout {
  max-width: 559px;
  height: 183px;
  border-radius: 16px;
  background: #FFF;
  position: relative;
  overflow: hidden;
}
.affiliate-payout-header {
  /* width: 529px; */
  height: 40px;
  border-radius: 12px;
  background: #FF984D;
  box-shadow: 0px -3px 3px 0px rgba(255, 255, 255, 0.20) inset, 0px 3px 3px 0px rgba(255, 255, 255, 0.20) inset;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  line-height: 121%; /* 21.78px */
  letter-spacing: -0.18px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 15px 10px 15px;
}
.affiliate-payout-gradient {
  background: #FF984D;
  border-radius: 9px;
  filter: blur(30px);
  height: 69px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.affiliate-payout-box {
  width: 71px;
  height: 103px;
  border-radius: 12px;
  background: #fffdf9;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.10) inset;
  color: rgba(0, 0, 0, 0.70);
  font-size: 42px;
  font-weight: 700;
  line-height: 121%; /* 50.82px */
  letter-spacing: -0.42px;
  background: linear-gradient(#fff3e1 ,#fffdf9,#fff3e1);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.affiliate-payout-main-earning {
  display: flex;
  gap: 6px;
  justify-content: center;
}
.affiliate-cash-out {
  max-width: 558px;
  min-height: 50px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #FFF;
  padding: 10px 11px 10px 15px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: #5B5B5B;
  font-size: 12px;
  font-weight: 500;
  line-height: 121%; /* 14.52px */
  letter-spacing: -0.12px;
  gap: 10px;
}
.affiliate-program-details {
  gap: 10px; 
  background: #EEEDEE;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 130.1%; /* 18.214px */
  letter-spacing: -0.21px;
  text-decoration: none;
}
.affiliate-program-details:hover{
  color: #333 !important;
}
.affiliate-div {
  border-radius: 16px;
  background: #FAFAFA;
  padding: 16px 20px 17px 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 10px;
}
.affiliate-div h2{
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 111%;
}
.affiliate-div p {
  color: #5B5B5B;
  font-size: 12px;
  font-weight: 500;
  line-height: 130.1%; /* 15.612px */
  letter-spacing: -0.18px;
}
.filter-action-count-dropdown {
  display: flex;
  height: 25px;
  padding: 2px 10px;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  background: #F3F3F3;
  cursor: pointer;
}
.filter-dropdown-item{
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: 8px 10px;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  line-height: 106.1%; /* 12.732px */
}
.filter-dropdown-item.active {
  cursor: not-allowed;
  background-color: #e9ecef;
}
.filter-dropdown-item:hover {
  background-color: #e9ecef;
}
.dropdown-item {
  border-radius: 5px;
  font-weight: 500;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 9px;
  font-weight: 500;
  font-size: 14px;
}
.dropdown-menu {
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  border-radius: 9px;
}
.sort-arrow {
  transition: 0.3s ease-in-out;
}
.flex-basis-100 {
  flex-basis: 100% !important;
}
.published-at {
  color: #333;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.36px;
  display: inline-flex;
  padding: 8px 13px;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.10);
}
.fs-16px {
  font-size: 16px;
}
.focus-none:focus{
  outline: none !important;
  border: none !important;
}
.cursor-text {
  cursor: text !important;
}
.opacity-50 {
opacity: 0.5 !important;
}
.dropdown-menu:not(.project-dropdown-menu) {
  max-height: 38vh;
  overflow-y: auto;
}
.code-products-option {
  max-width: 200px;
  /* display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px; */
}
.product-checked {
  max-width: 10px;
  height: 10px;
  border-radius: 5px !important;
}
.downgrade-discount-modal .modal-content{
  padding: 19px 28px 20px 18px !important;
  max-width: 477px;
}
.bg-green-upgrade {
  filter: hue-rotate(190deg) brightness(1.6);
}
.gap-10px {
  gap: 10px;
}
.min-width-auto {
  min-width: auto !important;
}
.gap-20px {
  gap: 20px !important;
}
.mt-25px {
  margin-top: 25px !important;
}
.project-divider {
  border-radius: 10.877px;
  background: #F3F3F3;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.project-theme-wheel{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.mb-20px {
  margin-bottom: 20px !important;
}
.fs-10px {
  font-size: 10px !important;
}
.text-grey {
  color: rgba(91, 91, 91, 0.50);
}
.project-new-inp {
  border-radius: 9px;
  background: #F3F3F3;
  padding: 9px 12px;
  color: #5B5B5B !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 106.1%;
  width: 100%;
}
.transparent-textarea {
  border: none;
  resize: none;
  background: transparent;
  outline: none;
  color: #5B5B5B !important;
  font-size: 12px;
  width: 100%;
  font-weight: 500;
}
.transparent-textarea:focus {
  border: none;
  outline: none;
}
.fs-18px {
  font-size: 18px !important;
}
.min-height-40px {
  min-height: 40px !important;
}

.switch-container {
  display: flex;
}

.switch {
  position: relative;
  width: 116px;
  height: 30px;
  background-color: #f3f3f3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.switch.on {
  background-color: #1188ff;
}

.switch-label {
  font-size: 12px;
  color: white;
  position: absolute;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.switch-handle {
  position: absolute;
  height: 24px;
  background-color: white;
  border-radius: 8px;
  top: 3px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width:60px;
  padding: 5px;
}

.switch.on .switch-handle {
  transform: translateX(50px);
}

.switch-handle-label {
  font-size: 12px;
  color: black;
  text-align: center;
  white-space: nowrap;
}

.social-search-box{
  justify-content: start !important;
  gap: 10px;
  width:100% !important;
}

.welcome-continue{
  position: absolute;
  z-index: 1;
  bottom: 25px;
  left: 0;
  right: 0;
}

.dropdown_calendar{
  margin-right: -8px !important;
}

.social-icons .blue-icon{
  padding-bottom: 5px;
}

.upgrade-modal-image{
  object-fit: cover;
  max-width: 100%;
  border-radius: 15px 15px 0 0;
}


.mr-1{
  margin-right: 5px;
}

.banner-type-selection{
  margin: 10px 0;
  display: flex;
  gap: 10px;
  align-items: center;
}

.banner-type-selection div{
  font-size: 14px;
  color:#aeaeae;
}

.loader-parent{
  display: flex;
  align-items: center;
}

.small-white-loader{
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
}

@media(max-width : 640px){
  .social-icons-box {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .add-block-btn span{
    display: none;
  }
}

@media (max-width: 549px)
{
  .banner-type-selection{
    flex-direction: column;
    align-items: start;
  }
  .section-footer {
    margin-left: 0 !important;
    margin-bottom: 5px !important;
  }

  .pagination-showing,
  .pagination-total{
    display: none !important;
  }

  .table-pagination {
    margin: 0 !important;
  }

  .table-pagination button{
    margin-left: 5px;
    margin-right: 0px !important;
  }

  .scrollbar-on-hover{
    max-height: inherit !important;
  }

  .new-editor-iframe iframe{
    height: 1805px !important
  }
}

@media(max-width : 499px){
  .social-icons-box {
    grid-template-columns: 1fr 1fr!important;
  }

  .social-block-cont{
    max-width:100% !important;
  }

  .empty-container .oval-para{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 10px;
    width: calc(100% - 50px);
    display: flex;
  }
}
