.button {
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
    color:#fff;
    box-shadow: 0px 4px 8px 0px #272B2E4D;
    font-family: inherit;
    display: flex;
    align-items: center;
    gap: 9px;
    justify-content: center;
}

.small {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0;
    vertical-align: middle;
    padding: 8px 10px;
    min-width: 80px;
}

.medium {
    padding: 10px 16px;
    font-size: 1rem;
}

.large {
    padding: 14px 20px;
    font-size: 1.2rem;
}

/* Colors */
.primary {
    background-color: #1188FF;
    color: white;
    width:100%;
    box-shadow: 0px 4px 8px 0px #1188FF4D;
    min-width: auto;
}

.primary:hover {
    background-color: #3f9efe;
}

.secondary {
    background: #272B2E;
    color: white;
}

.secondary:hover {
    background-color: #111;
}

.danger {
    background-color: #dc3545;
    color: white;
}

.danger:hover {
    background-color: #a71d2a;
}