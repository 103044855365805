/* #wrapper {
  max-width: 1520px;
} */
#wrapper .monetization-main {
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
}

#wrapper .left-side-bar .user-card {
  position: fixed;
  bottom: 0%;
  padding: 25px;
  left: 0%;
  width: inherit !important;
}

#wrapper .monetization-main .monetization-section {
  flex-basis: 100%;
  height: 100dvh;
  overflow-y: auto;
  padding: 22px 35px 35px 35px;
  /* padding-left: 50px; */
  position: relative !important;
}

#wrapper .monetization-main .monetization-section .section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#wrapper .monetization-main .monetization-section .section-main {
  width: 100%;
  display: flex;
  min-height: calc(var(--app-height) - 143px) !important;
  justify-content: space-between;
  flex-direction: column;
}

#wrapper .monetization-main .monetization-margin {
  /* margin: 10px 30px; */
  height: 100%
}

#wrapper .monetization-main .monetization-section .section-main .search-bar {
  flex: 3;
}

#wrapper .monetization-main .monetization-section .section-main .search-bar input {
  font-weight: 500 !important;
}

#wrapper .monetization-main .monetization-section .section-main .search-bar input::-moz-placeholder {
  font-weight: 500 !important;
}

#wrapper .monetization-main .monetization-section .section-main .search-bar input:-ms-input-placeholder {
  font-weight: 500 !important;
}

#wrapper .monetization-main .monetization-section .section-main .search-bar input::placeholder {
  font-weight: 500 !important;
}

#wrapper .monetization-main .monetization-section .section-main .filter-dropdown {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
}

#wrapper .monetization-main .monetization-section .section-main .filter-dropdown::after {
  display: none !important;
}

#wrapper .monetization-main .monetization-section .section-main .filter-dropdown:hover {
  background-color: #F3F3F3 !important;
}

#wrapper .monetization-main .monetization-section .section-main .table-action-dropdown::after {
  display: none !important;
}

#wrapper .monetization-main .monetization-section .section-main .filter-dropdown.show {
  background-color: #F3F3F3 !important;
}

#wrapper .monetization-main .monetization-section .section-main .filter-dropdown-menu li a:hover {
  background-color: #F3F3F3 !important;
  color: inherit !important;
}

#wrapper .monetization-main .monetization-section .section-main .filter-dropdown-menu li a span img {
  opacity: 0;
}

#wrapper .monetization-main .monetization-section .section-main .filter-dropdown-menu li a.active {
  background-color: #F3F3F3 !important;
  color: #4397F7 !important;
}

#wrapper .monetization-main .monetization-section .section-main .filter-dropdown-menu li a.active span {
  font-size: 16px !important;
}

#wrapper .monetization-main .monetization-section .section-main .filter-dropdown-menu li a.active span img {
  opacity: 1;
  margin-top: -4px !important;
}

#wrapper .monetization-main .monetization-section .section-main table thead {
  border-top: 1px solid #E8E9EA;
  border-bottom: 1px solid #E8E9EA;
}

#wrapper .monetization-main .monetization-section .section-main table thead th {
  font-weight: 500;
  color: #5B5B5B;
}

#wrapper .monetization-main .monetization-section .section-main table tbody {
  border-top: none !important;
  border-bottom: 1px solid #E8E9EA;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr {
  border: none !important;
  border-bottom: 1px solid #E8E9EA !important;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td {
  vertical-align: middle;
  border: none !important;
  font-weight: 500;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td .subscriber-name-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #03ACFF;
  color: white;
  border-radius: 50px;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td .table-action-dropdown {
  cursor: pointer;
  padding: 10px;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td .badge {
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td .badge-subscribed {
  color: #9B1DFF;
  background-color: #F2EEFF;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td .badge-subscribed svg {
  fill: #9B1DFF;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td .badge-unsubscribed {
  color: #FF1D53;
  background-color: #FFEEF1;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td .badge-unsubscribed svg {
  fill: #FF1D53;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td .badge-customer {
  color: #FFC01D;
  background-color: #FFFAEE;
}

#wrapper .monetization-main .monetization-section .section-main table tbody tr td .badge-customer svg {
  fill: #FFC01D;
}

#wrapper .monetization-main .monetization-section .section-main table .font-2x {
  font-size: 30px;
}

#wrapper .monetization-main .monetization-section .section-main .table> :not(caption)>*>* {
  border: none !important;
}

#wrapper .monetization-main .monetization-section .section-main .section-footer {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 12px;
  margin-top: 5px;
  margin-left: 10px;
}

#wrapper .monetization-main .monetization-section .section-main .section-footer .table-pagination {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 5px;
}

#wrapper .monetization-main .monetization-section .section-main .section-footer .table-pagination button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  text-align: center;
  background-color: #EEEDEE;
  color: #6B6B6B;
  border-radius: 10px !important;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 600;
}

#wrapper .monetization-main .monetization-section .section-main .section-footer .table-pagination button:hover {
  background-color: #212322 !important;
  color: white !important;
}

#wrapper .monetization-main .monetization-section .section-main .section-footer .table-pagination button.active {
  background-color: #212322 !important;
  color: white !important;
}

#wrapper .monetization-main .monetization-section::-webkit-scrollbar {
  width: 0.5em;
}

#wrapper .monetization-main .monetization-section::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}

#wrapper .monetization-main .modal-body::-webkit-scrollbar {
  background-color: transparent;
}
#wrapper .monetization-main .modal-body::-webkit-scrollbar-track {
  background-color: transparent;
}

#wrapper .monetization-main .modal-body::-webkit-scrollbar-thumb {
  background-color: black;
}

#newProductModal .modal-content {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

#newProductModal .modal-content .product-header {
  border-bottom: 1px solid rgba(12, 2, 43, 0.1) !important;
}

#newProductModal .modal-content .product-header .close {
  font-size: 2.5rem !important;
}

#newProductModal .modal-content .product-dropzone {
  height: 120px !important;
  border: 1px dashed #A0A0A0 !important;
  background-color: transparent;
  /* padding: 75px; */
}

#newProductModal .modal-content .product-dropzone .dropzone-desc {
  top: 25%;
}

#newProductModal .modal-content .product-style{
  width: 350px !important;
}

#newProductModal .modal-content .product-footer {
  width: 100%;
  /* background-color: #FAFAFA; */
  padding: 5px 15px;
  display: flex;
  align-items: center;
}

#newProductModal .modal-content .product-footer .close {
  border: 1px solid #E8E9EA;
  box-shadow: 0px 4px 15px rgba(49, 48, 67, 0.05);
}

#newProductModal .modal-content .product-footer button {
  padding: 10px 15px !important;
}

#newProductModal .modal-content .modal-header {
  padding: 5px 40px !important;
  padding-top: 10px !important;
}

#newProductModal .modal-content .modal-body {
  height: 515px !important;
  overflow-y: auto;
  padding: 20px !important;
}


#newProductModal .modal-content .modal-body #sendNewsletterContent {
  background: #F1F1F1 !important;
  border-radius: 20px;
  margin-top: 10px;
}

.accordion-button:not(.collapsed)::after {
  position: relative;
  top: -10px;
}
/* .accordion-button {
    background-color: white !important;
  border-radius: 3px !important;
  margin-top: 7px !important;
} */
.accordion-button:focus{
  box-shadow: none !important;
}

.faq-accordion-item, .faq-accordion-button {
  word-wrap: break-word;
  font-weight: 500; 
   border: none; 
   border-top-right-radius: 15px !important; 
   border-top-left-radius: 15px !important; 
   /* background-color: white !important; 
   color: black; */
}

.faq-accordion-button-light{
  background-color:white  !important; 
   color: black!important;
}

.faq-accordion-button-light::after{
  background-position: center !important; 
  /* border-radius: 22% !important; */
  padding: 15px !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000' %3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  background-color: #F9F9F9 !important;
}
.faq-accordion-button-dark{
  background-color: black !important; 
   color: white !important;
}
.faq-accordion-button-dark::after{
  background-position: center !important; 
  border-radius: 22% !important;
  padding: 15px !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF' %3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  background-color: #333131 !important;
}

.faq-accordion-button:not(.collapsed) {
  box-shadow: none;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: none !important;
  outline: none !important; 

 }

/* .faq-accordion-button::after {
 background-position: center; 
 border-radius: 22%; 
 padding: 15px; 
 background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important; 
 background-color: gray !important; 
} */

.faq-accordion-item:last-of-type .faq-accordion-button.collapsed {
   border-bottom-right-radius: 12px; 
   border-bottom-left-radius: 12px; 
}


#newProductModal .up-in-toggle.bg-white {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 34px;
  color: #5B5B5B;
  box-shadow: none;
}

#newProductModal .up-in-toggle.bg-white label {
  display: inline-block;
  width: 60px;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
  padding: 6px 16px;
  text-align: center;
  width: 50%;
  float: left;
  transition: all 0.1s ease-in-out;
}

#newProductModal .up-in-toggle.bg-white label:hover {
  cursor: pointer;
}

#newProductModal .up-in-toggle.bg-white input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
  flex-basis: auto;
}

#newProductModal .up-in-toggle.bg-white input:checked+label {
  border: 2px solid #FF1451;
  font-weight: 500;
}

#newProductModal .up-in-toggle.bg-white input:checked+label img {
  opacity: 1;
}

#newProductModal .up-in-toggle.bg-white label {
  font-weight: 500;
  border: 2px solid #E1E1E1;
  border-radius: 15px !important;
  /* padding-left: 10px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px; */
  padding: 10px 20px;
}

.membershipstyle {
  border: 2px solid #f2f2f2 !important;


}

#newProductModal .up-in-toggle.bg-white label img {
  opacity: 0;
}

#newProductModal .up-in-toggle.bg-white label:last-of-type {
  border-radius: 20px;
}

#newProductModal .up-in-toggle.bg-white label:first-of-type {
  border-radius: 20px;
  margin-right: 15px !important;
}

#newProductModal .up-in-toggle.bg-f7f7f8 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 12px;
  color: #5B5B5B;
  background-color: #f7f7f8;
  width: 196px !important;
  font-size: 12px;
}

.main-courser {
  cursor: pointer;

}

.main-btn-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 4px 5px 4px !important;
  border-radius: 8px;
  color: #5B5B5B;
  background-color: #F2F2F2;
}

.main-btn-header .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-radius: 8px;
  border: none !important;
}

.main-btn-header .nav-item .nav-link {
  border: none !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out !important;
 /* transition: none !important; */
}

.menu-btn {
  cursor: pointer;
  width: 100%;
  color: red;
}

#newProductModal .up-in-toggle.bg-f7f7f8 label {
  display: inline-block;
  padding: 6px 16px;
  text-align: center;
  width: 50%;
  height: 35px;
  float: left;
  transition: all 0.1s ease-in-out;
  border-radius: 9px;
}

#newProductModal .up-in-toggle.bg-f7f7f8 label:hover {
  cursor: pointer;
}

#newProductModal .up-in-toggle.bg-f7f7f8 input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
  flex-basis: auto;
}

.product-style.up-in-toggle.bg-f7f7f8 .light-inp:checked+label {
  font-weight: 500;
  color: black !important;
  background-color: white !important;
}

#newProductModal .up-in-toggle.bg-f7f7f8 input:checked+label {
  font-weight: 500;
  color: white;
  background-color: black;
}

#newProductModal .up-in-toggle.bg-f7f7f8 input:checked+label svg path{
  /* fill: #fff; */
  stroke: #fff;
}

#newProductModal .up-in-toggle.bg-f7f7f8 label:first-of-type {
  border-radius: 20px;
}

#newProductModal .up-in-toggle.bg-f7f7f8 label:last-of-type {
  border-radius: 20px;
}

.product-description-main {
  border: 1px solid #E8E9EA;
  border-radius: 14px;
  min-height: 200px;
}

.product-description-header {
  width: 100%;
  background: #F5F9FC;
  border-radius: 14px 13px 0px 0px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.product-description-header-item {
  background: white;
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  width: auto;
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
}

.product-description-header-item:hover {
  background: #f3f3f3;
}

.product-description-body {
  padding: 20px 10px;
}

.product-description-input {
  border: none;
  width: 100%;
  color: #6B6B6B;
  font-size: 16px !important;
}

.product-description-input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.product-description-input::-moz-placeholder {
  font-size: 16px !important;
  color: #CDCDCD;
}

.product-description-input:-ms-input-placeholder {
  font-size: 16px !important;
  color: #CDCDCD;
}

.product-description-input::placeholder {
  font-size: 16px !important;
  color: #CDCDCD;
}

.product-description-dropdown-menu .dropdown-item:focus, .product-description-dropdown-menu .dropdown-item:hover {
  border-radius: 6px !important;
}

.product-theme {
  min-width: 50px;
  height: 50px;
  border-radius: 8px;
  border: 2px solid transparent;
  margin-right: 5px;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  margin-bottom: 10px;
}

.product-theme div {
  width: 100%;
  height: 100%;
  /* border-radius: 50px; */
}

.product-theme.theme-selection-indiv div {
  width: 90px;
  height: 90px;
  /* border-radius: 50px; */
}

.product-theme.theme-selection-indiv {
  height: auto;

}

.product-theme.active {
  border: 2px solid #FF1451;
}

#productFiles .form-group {
  width: 250px;
}

/*# sourceMappingURL=monetization.css.map */

/* croppie */
#productModal {
  max-width: 550px;
}
#postWidth {
  max-width: 900px;
}
#productCroppie {
  max-width: 900px !important;
}
/* custom_theme_modal */
#custom_theme_modal {
  max-width: 850px
}

#custom_theme_modal .IroColorPicker {
  justify-content: space-between;
  width: 220px;
}

#custom_theme_modal .iro-picker-container input {
  width: 150px;
  border: 1
}



.producttoggle {
  width: 100% !important;
  border-radius: 10px !important;
}

.mian-google-style {
  width: 70% !important;
  border-radius: 10px !important;
  padding: 3px !important;
}
.connect-calendar-options{
  max-width: 500px !important
}
.up-in-select {
  /* width: 100%; */
  border-radius: 8px;
  /* padding: 11px; */
  outline: none;
  border: none;

}

.up-main {
  width: 70%;
  border-radius: 7px;
  padding: 10px;
  outline: none;
  border: none;
}

.free-container {
  border: 1px solid gray;
  border-radius: 10px;
  font-size: 14px;
}

.payment-container {
  border: 1px solid gray;
  border-radius: 10px;
  font-size: 14px;
  height: 57px;
}
.main-wrapper{
  overflow-x: hidden;
  overflow-y: hidden;
}
.from-inp {
  border: 1px solid gray;
  border-radius: 7px;
}

.btn-style {
  padding: 9px 0px;
  border-radius: 15px;
  color: white;
  background-color: #2E2E2E;
  border: none;
  width: 170px;
  font-size: 19px;
  text-align: center;
  cursor: pointer;
}

.man-inp{
  border-radius: 12px !important;
  border: 1px solid #E1E1E1 !important;
  color:rgba(14, 18, 59, 0.5) !important;
  font-weight: 600;
  padding: 10px 15px !important;
  height: auto;
}

.duration-inp {
   border: 1px solid #E1E1E1;
  padding: 12px;
  border-radius: 12px;
  width: 100%;
  font-size: 16px;
  /* color:rgba(14, 18, 59, 0.5) !important ;  */
  outline: none;
  font-weight: 600;
  color: rgba(14, 18, 59, 0.5);


}
.link-inp{
  border: none;
  background: none;
  outline: none;
  height: 100%;
  flex-basis: 95%;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.google-link-but {
  margin-left: 4px;
  padding: 9px;
  border-radius: 14px;
  color: white;
  background-color: #2E2E2E;
  border: none;
  width: 215px;
}

.btnTxtDark li button {
  color: black !important;
}

.placeHolder-center::-webkit-input-placeholder {
  text-align: start;
}

.placeHolder-center:-moz-placeholder {
  text-align: start;
}



.wheel .IroColorPicker {
  align-items: center;
  gap: 10px;
}

.place-but {
  background-color: gray;
  border-radius: 2px;
  width: 3vh;
  color: white;
  font-weight: 3px;
  margin-left: 3px;
}


.url-dotted {
  border: 2px dashed #A0A0A0 !important;
  padding: 10px;
  border-radius: 16px;
}

.pricing-text{
  font-size: 20px;
}

.main {
  width: 90%;
  border-radius: 7px;
  padding: 8px;
  outline: none;
  border: none;
  color: white;
  background-color: black;

}

.mobile-container {
  overflow-y: hidden;
  width: 95%;
  /* min-height: 97%; */
  height: 97%;
  margin-top: 2%;
  border-radius: 9px;
  /* max-width: 400px; */
  /* margin-left: auto; */
  /* margin-right: 50px; */
  border-radius: 16px;
  box-shadow: 0px 11px 53px 0px rgba(6, 4, 30, 0.10);
  margin-left: 5px;

}



.link-style {

  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(88, 86, 107, 0.11);
  padding: 10px 8px;
  font-size: 14px;
  width: 100%;
  background-color: #606774;

}

.form-groups {
  width: 100%;
}

.mobile-screen {
  background-color: #333131;
  height: 210px;
  border-radius: 13px;
  margin: 15px !important;
  overflow: hidden;
  width: 100%;
}
.binary-img{
  min-height: 220px;
  border-radius: 13px;
  margin-top: 15px !important;
  width: 100%;
}
.wrapper{
  overflow-x: hidden;
}


.img-header {
  position: relative;
  text-align: center;
  margin-top: -40px
}

.text-styling {
  font-weight: bold;
  color: white;
  font-size: 20px;
  margin-top: 10px;
  word-wrap: break-word;
}

.accordion-items {
  color: white;
  background-color: black;
  border-radius: 10px;
}

.para-style {
  font-size: 15px;
  color: white;
  margin-top: -10px;
  word-wrap: break-word;
}

.buy-btnstyle {
  background-color: #B1D940;
  padding: 5px;
  border: none;
  border-radius: 9px;
  width: 27%;
}



.dollor-btn {
  background-color: #333131;
  padding: 5px;
  border: none;
  border-radius: 9px;
  min-width: 70px;
  margin-left: 10px;
  font-weight: bold;
  color: white;
  padding: 9px;
}

.question-header {
  background-color: #333131;
  width: 90%;
  height: 140px;
  border-radius: 10px;
  margin-left: 25px;
  margin-top: 22px;
  padding: 15px;
}

.google-link-buton {
  background-color: #2E2E2E;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 6px;
  width: 22%;
}

.google-question-buton {
  background-color: #2E2E2E;
  color: white;
  border-radius: 5px;
  border-radius: 12px;
  border: none;
  padding: 6px;
  width: 25%;
  height: 48px;
}

.add-question-buton {
  background-color: #2E2E2E;
  color: white;
  border-radius: 16px;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-faqs-buton{
  background-color: #2E2E2E;
  color: white;
  border-radius: 16px;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-header {
  background-color: none !important;
}


.main-width{
  height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden !important;
}


@media (max-width:663px) {
  .question-header {
    margin-left: 3vh;
    width: 90%;
    height: 120px;
  }




  .mobile-container {
    width: 100%;
    height: 583px;
  }

  .img-header {
    margin-top: -40px;
  }
}

.parent-accent {
  display: flex;
  align-items: center;
}

/* style={{ overflowX: "hidden", height: "90%" }} */
.scrool-container{
   overflow-x: hidden;
   height: 100%;
   padding: 20px;
}

@media screen and (min-width:767px) {
  .p0Imp {
    padding: 0 !important;
  }
}

@media screen and (max-width:767px) {
  /* #newProductModal .modal-content .modal-body {
    height: calc(100%) !important;
  } */
  .scrool-container{
    overflow-y: hidden;
  }
  .mobile-container{
    height: 100%;
  }
}

@media screen and (max-width:550px) {
  .parent-accent {
    flex-direction: column;
  }
}



.image-tool--withBackground .image-tool__image {
  background: #f3f3f3 !important;
}

.image-tool__image {
  border-radius: 15px !important;
}

.image-tool--stretched .image-tool__image-picture {
  border-radius: 15px !important;
}

.image-tool--withBackground .image-tool__image-picture {
  border-radius: 15px !important;
}

.input-style{
border: none;
background: none;
    outline: none;
    height: 100%;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;
    color: red;
    /* color: rgba(14, 18, 59, 0.5) !important; */
}

.product-nav-link {
  color: #000;
  border-radius: 12px !important;
}

.widget-block {

  /* box-shadow: 0px 0px 15px rgb(17 21 26 / 15%); */
  border-radius: 12px;
  overflow: hidden;
  /* margin-top: 15px; */
  padding: 20px 30px;
  /* background-color: #333131; */
  /* margin: 0px 22px; */
  width: 85%;
}

.widget-block h4 {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.widget-block p {
  font-size: 15px !important;
}

.widget-block form .form-group {
  margin: 0;
}


.product-title {
  color: blue !important;
  background-color: red
  ;
}

.faq-accordion-button:focus {
  box-shadow: none !important;
  z-index: none !important;
  ;
}
.faq-accordion-button:not(:focus) {
  box-shadow: none !important;
  z-index: none !important;
}

.faq-accordion-item {
  font-size: 18px;
}





.faq-accordion-item:last-of-type {
  border-bottom-right-radius: 25%;
  border-bottom-left-radius: 25%;
}

.faq-accordion-body {
  padding-top: 0px;
  border: none;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  text-align: start;
}
.faq-accordion-body-light{
  background: white !important;
  color: black !important;
}
.faq-accordion-body-dark{
  background-color: black !important; 
   color: white !important;
}
.savechang-style{
  margin-left: 28px;
}
@media screen and (min-width: 768px) {
  .noHorizontalPadding {
    padding: 0 !important;
  }
}

.timezone-select-container {
  position: relative;
  display: inline-block;
}

.timezone-select-input-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  min-width: 335px !important;
  margin-top: 15px;
  font-size: 15px;
}

.timezone-select-input-container .globe-icon {
  margin:0 10px;
}

.availability-dropdown{
  margin-top: 15px;
  background-color: #FFFFFF;
  border: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding-top: 1px;
  width: 170px;
  height: 160px;
  position: absolute;
  overflow-y: scroll;
}
 .timezone-dropdown {
  background-color: #FFFFFF;
  border: none;
  border-bottom-left-radius:  15px;
  border-bottom-right-radius:  15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding-top: 1px; /* add some top padding to create space */
  height: 256px;
  width:335px;
  position: absolute;
  z-index: 12;
}
.availability-dropdown::-webkit-scrollbar ,.timezone-dropdown::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.availability-dropdown::-webkit-scrollbar-thumb , .timezone-dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.availability-dropdown::-webkit-scrollbar-thumb:hover , .timezone-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.availability-dropdown::-webkit-scrollbar-track , .timezone-dropdown::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.link-style-dark{
  color: black !important;
}
.link-style-light{
color: white !important;
}
.url-style{
color: red !important;
}
.bg-input{
  display: flex;

  background-color: #f1f1f1;
  padding: 5px;
  margin: 10px 20px 10px 10px;
  border-radius: 10px ;
  width: 90% !important;
}
.team-input-container{
  display: flex;
}
.team-input-container small{
  font-weight: 500;

}
.team-inp{
  height: 45px;
  padding: 12px;
  border-radius: 12px;
  width: 400px;
  border: 1px solid  #E1E1E1 ;
}
.team-list-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
  /* margin-bottom: 20px; */
}
.team-list{
display: flex;
/* border: 1px dashed #A0A0A0 ; */
/* width: 400px; */
background-color: #EEEDEE;
justify-content: space-between;
border-radius: 14px;
align-items: center;
height: 50px;
font-size: 12px;
}
.team-member-name {
  /* width: 230px; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
@media   (max-width:480px) {
  .team-inp{
    width: 88%;
  }
  /* .team-list{
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 10px;
    height: auto;
  }
  .team-list div:nth-child(2){
    padding-right: 10px;
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: center;
  } */
  .member-name-container{
    text-align: left !important;
    justify-content: flex-start !important;
  }
  .team-list button{
    font-size: 12px;
  }
  .team-list button img{
    width: 15px;
  }
}
.name-lis-scroll{
  overflow: auto;
  overflow-x: hidden;
   /* min-height: 250px !important;
   max-height: 250px !important; */
}

.name-lis-scroll::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
  
}
.name-lis-scroll::-webkit-scrollbar-track {
  background-color:transparent;

}
.name-lis-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 3px;
}

@media (max-width:1500px) {
  .name-lis-scroll{
    min-height: 220px !important;
    max-height: 220px !important;
  }
}

 .timezone-search-bar{ 
  width: 100% !important;
}
.availability-option {
  display: flex;
  justify-content: space-evenly !important;
  padding: 10px;
  cursor: pointer;
  margin-left: 40px;
}
.availability-option-check {
  color: #4397F7;
  display: flex;
  justify-content: space-evenly !important;
  padding: 10px;
  cursor: pointer;
}
.availability-option-check::before{
  content: '\2713';
  display: inline-block;
  color: #4397F7;
}
.timezone-option {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.availability-option:hover , .timezone-option:hover{
  background-color: #f1f1f1;
  border-radius: 10px ; 
  margin-right: 0;
}

.timezone-option-city {
  font-weight: bold;
  color: "#333333";
}

.timezone-option-time{
  color: #6b6b6b !important; 
  font-weight: bold !important;
  font-size: 18px !important;
}
.exit-style{
  margin-left: 2vh;
}

.selected-timezone {
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  padding: 10px;
  border-radius: 5px;
  /* background-color: #fff; */
  box-shadow: 0;
}
.product-type-img{
    transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -ms-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
}
.product-type-img:hover{
  transform: scale(1.05);
}
.border-less{
  border: none !important;
}
.btn-shadowed{
  box-shadow: 0px 4px 15px rgba(49, 48, 67, 0.05);
  border:1px solid #E8E9EA;
}
.faq-input-fields::placeholder{
  font-weight: 500 !important;
  color: #CDCDCD;
}

#newProductModal .modal-content .modal-body::-webkit-scrollbar {
  width: 0.25em;
} 
#newProductModal .modal-content .modal-body::-webkit-scrollbar-thumb {
  background-color: #b3b1b1;
  border: 4px solid #b3b1b1;
  border-radius: 12px;
  background-clip: padding-box;  
}

.scrool-container::-webkit-scrollbar {
  width: 0.25em;
} 
.scrool-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.faq-input-fields{
 width: 100%; 
 border: none;
 outline: none;
 font-size: 16px;
 font-weight: bold;
 color: rgba(14, 18, 59, 0.5)
}


@media (max-width: 768px) {
  #wrapper .monetization-main {
    height: auto !important;
  }
  .product-tab{
    font-size: 14px !important;
  }
  .product-icon{
    width: 18px !important;
  }
  .dark-style{
   font-size: 14px;
  }
  .savechang-style{
    margin-left: 0px !important;
  }
  .exit-style{
    margin-left: 12px;
  }
  #newProductModal .modal-content .modal-body{
    padding: 10px !important
  }
  .faq-input-fields{
  width: 100%;
  }
}
.theme-text{
  padding-left: 8px;
}
.accent-text{
  padding-left: 6px;
}

@media (max-width:480px) {
  .product-tab{
    font-size: 15px !important;
  }
  #newProductModal .up-in-toggle.bg-f7f7f8{
   width: 100% !important;
  }
  .product-icon{
    width: 14px !important;
  }

  .theme-text{
    font-size: 13px !important;
  }
  .dark-style{
    font-size: 16px;
  }
  .input-style{
    width: 100% !important;
  }
 .accent-text{
  font-size: 13px;
 }
 .input-text{
  font-size: 16px;
 }
 .pricing-text{
  font-size: 15px;
}
.pricing-text{
  font-size: 15px;
}
.mian-google-style{
  width: 100% !important;
}
#newProductModal .up-in-toggle.bg-white{
  display: flex;
  flex-direction: column;
}
.pay-class{
  margin-top: -15px;
}
.pricing-text{
  font-size: 20px !important;
}
#newProductModal .up-in-toggle.bg-white label{
  height: 83px;
  }
  .text-muted-light{
    margin-top: -4px !important;
  }
#newProductModal .up-in-toggle.bg-white label{
  width: 100%;
  margin-top: 7px;
}
#newProductModal .up-in-toggle.bg-white label:first-of-type{
  margin-right: 0px !important;
}
.content-img{
  width: 17px !important;
}
.content-tab{
  font-size: 13px;
}

}

@media (max-width:454px) {
.days-tab{
  font-size: 13px;
}  
.main-btn-header .nav-item .nav-link{
  padding: 8px 3px !important;
}
}

@media (max-width:413px) {
  .product-tab{
    font-size:14px !important;
  }
  .font-style{
    font-size: 1px;
 }
 .pricing-text{
  font-size: 14px;
}
#newProductModal .modal-content .product-dropzone{
    height: 150px !important; 
}
#newProductModal .modal-content .modal-body{
  padding: 10px !important
}
  

}
@media (max-width:372px) {
  .text-muted-light{
    margin-top: -2px !important;
  }
}
.break-between{
  background-color: #F3F3F3;
  padding: 8px;
  border-radius: 7px;
}
.break-between select{
  border: none;
  background: none;
  outline: none;
  margin-left: 5px;
}
.break-between select option:checked{
  background-color: #F3F3F3;
}

.product-types p{
  display: inline-flex;
  padding: 8px 13px;
  align-items: center;
  gap: 6px;
  border-radius: 9px;

  text-align: center;
  font-family: GT Walsheim Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 111%; /* 15.54px */
  letter-spacing: -0.42px;
}
.Digital p {
  background: #FFFAEE;
  color: #FFC01D;
}
.Meeting p {
  background: #F2EEFF;
  color: #9B1DFF;
}
.Lead p {
  background: #FFEEF1;
  color: #FF1D53;
}
.products-filter {
  
  display: flex;
  /* background: #F3F3F3; */
  padding: 8px;
  justify-content: flex-end;
  /* align-items: center; */

}
.products-filter span {
  border-radius: 7px;
  background: #F3F3F3;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}
.products-filter span.active,.products-filter span:hover {
  background: #212322;
  color: #FFFFFF;
  cursor: pointer;
}
.product-slider-buttons{
  border: none !important;
  background: none !important;
}
.time-slot-btn{
  padding: 9px 0px;
  border-radius: 15px;
  color: white;
  background-color: #2E2E2E;
  border: none;
  width: 130px;
  font-size: 19px;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
}
/* unaiz css start */
.full-flex{
  gap: 10px;
}
.search-u{
  width: 250px;
  padding: 10px;
  background-color: #F3F3F3;
  border-radius: 10px;
  height: 30px;
}
.u-input{
  font-size: 12px;
  font-style: normal;
  line-height: 111%; /* 13.32px */
  background-color: transparent !important;
  padding: 0 0 0 7px !important;
}
.u-input::placeholder{
  color: #6B6B6B !important;
  font-weight: 500;
}
.prodname{
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 111%; /* 15.54px */
  letter-spacing: -0.42px;
}
.sec-main-u{
  background-color: #FAFAFA;
  border-radius: 16px;
  padding: 10px;
  margin-top: 21px;
  font-size: 14px !important;
}
.dashview-u {
  /* background-color: #fff; */
  border-radius: 10px !important;
  padding: 10px;
  border: none !important;
  overflow: hidden !important;
  position: sticky;
  top: 0;
  background: #FAFAFA;
}
.table-responsive::-webkit-scrollbar{
  display: none !important;
}
.dashview-u th{
  background: none !important;
}
.product-t-head{
  width: 50% !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #6B6B6B;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.filtname{
  color: #333;
  font-family: GT Walsheim Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 111%; /* 15.54px */
  text-transform: capitalize;
}
.side-bar-headings {
  color: #000;
  font-family: GT Walsheim Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
}
.section-footer{
  padding: 0 !important;
}
.pagebtn-u >button{
  color: #6B6B6B !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.aud-leftborder{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.th-end-u{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.result-foot{
  color: #6B6B6B;
  font-family: GT Walsheim Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 111%; /* 15.54px */
}
.sort-u{
  border-radius: 9px;
  background: #F3F3F3;
  padding: 7px 10px;
  cursor: pointer;
  margin-bottom: -5px;
  color: #6B6B6B;
  font-size: 14px;
  font-weight: 500;
}
.sort-u2{
  width: fit-content;
  border-radius: 9px;
  background: #F3F3F3;
  padding: 7px 10px;
  cursor: pointer;
  margin-bottom: -5px;
  color: #6B6B6B;
  font-size: 14px;
  font-weight: 500;
  justify-content: flex-start;
}
.imp-border-u{
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  margin-right: 10px;
}
.img-object-fit{
  object-fit: cover;
  object-position: center;
}
.thead-padd{
  padding:0 !important;
  padding-bottom:10px !important;
}
.thead-padd:first-child {
  padding: 0 !important;
  padding-left: 10px !important;
  padding-bottom:10px !important;
}
.thead-padd.selected {
  padding-top: 0 !important;
  padding-right: 0 !important;
}
p{
  margin: none !important; 
  padding: none !important;
}
.newbtn-u{
  color: #FFF;
  text-align: center;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 106.1% !important;
  /* padding: 10px 15px; */
  white-space: nowrap;
  height: 30px;
}
/* unaiz Audiance page css */
.sec-head-u>h1{
  color: #03021C;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 111%; /* 24.42px */
}
.btn-import{
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #F3F3F3 !important;
  color: #333;
  text-align: center;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500;
  height: 30px;
}
.export-u{
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.Export-btn-u{
  border-radius: 9px;
  padding: 10px 15px;
  height: 30px;
}
.getwidth1{
  width: 10%;
}
.getwidth2{
  width: 34%;
}
.nextprvbtn{
  width: fit-content !important;
}
.aud-text{
  font-size: 14px;
}
.btn-red{
  background: #FF1451 !important;
}
@media (min-width:1441px) {
  .product-t-head{
    width: 60%;
  }
  .getwidth2{
    width: 50%;
  }
}
@media (max-width:1310px) {
  .product-t-head{
    width: 40%;
  }
  .getwidth2{
    width: 30%;
  }
}
@media (max-width:1260px) {
  .getwidth2{
    width:  25%;
  }
}
@media (max-width:1190px) {
  .hide-for-tab{
    width: 10%;
  }
}
@media (max-width:1080px) {
  .community-section {
    padding: 25px !important;
    padding-bottom: 0px !important;
  }
    .getwidth2{
      width: 26%;
    }
}
@media (max-width:990px) {
  .product-t-head{
    width: 35% !important;
  }
  /* .getwidth2{
    width: 22%;
  } */
}

@media (max-width:870px) {
  .product-t-head{
    width: auto;
  }
  .d-tab-none{
    display: none !important;
  }
  .getwidth2 {
    width: 35%;
  }
}
@media (max-width:812px) {
  .product-t-head{
    width: 40%;
  }
  .hide-for-tab{
    display: none !important;
  }

}
@media (max-width:767px) {
  .d-none-u{
    display: none !important;
  }
  #wrapper .monetization-main .monetization-section {
    flex-basis: 100%;
    height: calc(100dvh - 145px);
    overflow-y: auto;
    padding: 20px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
}

@media (max-width:640px) {
 
  .sec-head-u{
      flex-direction: column;
      align-items: start !important;
  }
  .full-flex{
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  .search-u {
    width: 78%
  }
}
@media (max-width:375px) {
  .result-foot{
    display: none;
  }
  .section-footer{
    justify-content: flex-end !important;
  }
}
/* unaiz css end */
.currency-dropdown {
  border-radius: 9px;
  background: #FAFAFA;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.currency-select-box{
  border-radius: 11px;
  background: #F3F3F3;
  padding: 3px 12px;
  cursor: pointer;
}
.currency-select-box select{
  border: none;
  background: #F3F3F3;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  color: #000;
}
.zaapGlobal-input{
  width: 100%;
  border-radius: 9px;
  background: #EEEDEE;
  color: #333 !important;
  font-size: 12px;
  font-weight: 500;
  padding: 9px 15px;
  border: none;
  min-height: 32px;
  resize: none;
  max-height: 200px;
}
.zaapGlobal-input::placeholder{
  color: rgba(91, 91, 91, 0.50) !important;
}
.zaapGlobal-input:focus-visible {
  outline: none;
}
.price_type_check input:checked+label {
  border: none !important;
  background: #1188FF;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
}
.font-weight-500{
  font-weight: 500 !important;
}
.price_type_check input label{
  padding:13px !important;
}
.mt-5px{
  margin-top:5px !important;
  margin-bottom: 0 !important;
}
.mt-10px{
  margin-top:10px !important;
  margin-bottom: 0 !important;
}
.mt-15px{
  margin-top:15px !important;
  margin-bottom: 0 !important;
}
.mt-20px{
  margin-top:20px !important;
  margin-bottom: 0 !important;
}
.mt-30px{
  margin-top: 30px !important;
  margin-bottom: 0 !important;
}
.price_type_check label {
  border: none !important;
  border-radius: 14px;
  background: #EEEDEE;
  text-align: left !important;
  padding: 13px !important;
  font-size: 14px;
}
.modal-foot-u{
  background: #FAFAFA;
  padding: 20px;
  border-radius:  0 0 20px 20px ;
}
.newGraybtn{
  border-radius: 9px;
  background: #F3F3F3;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  gap: 6px;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  border: none;
  padding: 8px 15px;
  margin-top: 9px;
}
.inset-0{
  inset: 0;
}
.prevContainer{
  display: flex;
  gap: 11px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 9px;
}
.prevImageU{
  width: 80px;
  border-radius: 9px;
}
.Product-preview-mobile{
  min-width: 830px;
  width: 100%;
  height: calc(100vh - 100px);
  border-radius: 35px;
  border: 5px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  overflow: hidden;
}
.pagePrev-link{
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 9px;
  background: #F3F3F3;
  padding: 3px;
  margin-bottom: -1rem;
  z-index: 5;
}
.prevlink-text{
    width: 100%;
    overflow: auto;
    text-wrap: nowrap;
}
.Blue-btn{
  border: none;
  outline: none;
  background-color: #18F;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  gap: 5px;
  border-radius: 6px;
  width: 73px;
  height: 24px;
}
.Blue-btn>img{
  width: 12px;
  filter: brightness(0) invert(1) grayscale(1); 
}
.main-cont{
  display: flex;
  width: 100%;
  overflow: auto;
}
.Edit-area {
  width: 100%;
  padding: 30px 20px;
}
.bg-blue{
  border: none;
  outline: none;
  border-radius: 11px !important;
  background: #18F;
  display: flex;
  width: 182px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  text-wrap: nowrap;
}
.bg-gray{
  background: #F3F3F3;
  color: #333;
}
.Product-Editor{
  display: flex;
  gap: 7px;
}
.rightbar-u{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 20px 30px 0;
}
.editor-linku{
  background: #FAFAFA;
  border-radius: 16px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ProductBody {
  overflow-y: auto;
  width: 100%;
  /* height: calc(100vh - 240px); */
  height: 94%;
  padding-bottom: 80px;
  padding-right: 5rem;
}
.ProductBody::-webkit-scrollbar{
  display: none;
}
.input-style2{
  font-size: 12px;
}
.p20-u{
  padding: 20px;
  padding-bottom: 0;
}
.z-index-3{
  z-index: 16;
}
.productHead1{
  color: #03021C;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
.productlabels{
  color: #333;
  font-size: 14px !important;
  font-weight: 500;
}
.productlabel2{
  font-size: 14px !important;
  font-weight: 500;
  letter-spacing: -0.21px;
}
.productlabel3{
  font-size: 12px !important;
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
}
.mb--u{
  margin-bottom: -10px;
}
.theme-toggle{
  Color: black;
  margin-top: 13px;
  border-radius: 13px;
}
.theme-toggle label:last-of-type ,label:first-of-type:not(.small-box){
  border-radius: 9px;
}
.productnewdropzone{
  max-width: 453px;
  width: 100%;
  height: 127px;
  border-radius: 34px;
  background-color: #fff;
  margin-top: 20px !important;
}
.card-container{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  /* justify-content: center; */
  overflow: auto;
  height: 71vh;
  padding: 15px 20px 0px 20px;
}
.card-container::-webkit-scrollbar{
  display: none;
}
.card-container .section-cards{
  width: 176px;
  height: 233px;
  border-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
}
.section-cards img{
  width: 100%;
}
.card-container .section-cards:hover{
  transform: scale(1.02);
}
.line-height-none{
  line-height: inherit;
}
.switch-u{
  width: 30px;
  height: 15px;
}
.switch-u input:checked + .slider {
  background-color: #FF1451;
}
.switch-u .slider:before {
  height: 11px;
  width: 11px;
  left: 4px;
  bottom: 2px;
}
.switch-u input:checked + .slider:before {
  transform: translateX(100%);
}
.add-question-btn{
  border-radius: 9px;
  background: #2E2E2E;
  box-shadow: 0px 4px 15px 0px rgba(49, 48, 67, 0.10);
  height: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: none;
  outline: none;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
}
.arcordian-u .accordion-header button{
  border-radius: 11px !important;
  background: #EEEDEE !important;
  padding: 10px 10px 10px 17px !important;
}
.arcordian-u .accordion-button:after {
  background-color: #fff;
  background-position: 50%;
  border-radius: 9px;
  height: 30px;
  margin-top: 0!important;
  width: 30px;
}
.arcordian-u .accordion-button:not(.collapsed)::after {
  top: 0px;
}
.qna-box{
  width: 29px;
  height: 29px;
  border-radius: 7px;
  background: #FFF;
  color: #333;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qna-box2{
  width: 100%;
  height: 25px;
  border-radius: 7px;
  padding: 0 10px;
  background: #FFF;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qnaContainer{
  border-radius: 14px;
  background: #EEEDEE;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.w-85{
  width: 85%;
}
.acc-body-u{
  padding: 20px 10px 10px 10px !important;
}
.mainTitle{
  font-size: 22px !important;
  font-weight: 500 !important;
}
.nav-item .nav-link{
  font-size: 14px;
}
@media (max-width:1285px) {
  .main-cont {
    flex-direction: column;
}
.rightbar-u {
  padding: 18px 40px 30px 40px;
}
  .Product-preview-mobile {
    max-width: 722px;
    min-width: 100%;
    width: 100%;
  }
}
@media (max-width:1028px) {
  .main-cont{
    width: calc(100% - 78px);
    margin-left: 78px;
  }
}
@media (max-width:771px) {
  .ProductBody {
    padding-right: 0;
  }
}
@media (max-width:670px) {
  .card-container{
    padding-bottom: 100px;
  }
}
@media (max-width:767px) {
  .main-cont{
    width:100%;
    margin-left:0;
    padding-bottom: 50px;
  }
}
@media (max-width:500px) {
  .rightbar-u {
    padding: 18px 16px 30px 16px;
  } 
  .Edit-area {
    padding: 20px;
  }
}
@media (max-width:430px) {
  .content-img {
    display: none;
}
.productnewdropzone .text-muted-light,.text-gray{
  font-size: 13px;
}
}
.up-in-toggle-alignment.vertical {
  width: 35% !important;
}
.up-in-toggle-alignment.horizontal {
  width: 25% !important;
}
.demo-banner.false{
  display: none;
}